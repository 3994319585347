import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import media from '../../../utilities/breakpoints.js';
import { customEase } from '../../../utilities/transitions.js';

const StyledNav = styled.nav`
  position: sticky;
  top: 0;
  margin-top: 40px;
  z-index: 10;
  &.container {
    padding: 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;

    li {
      background-color: ${(props) => props.theme.primary};
      display: flex;
      text-align: center;
      flex-basis: 50%;
      ${media.lg`
        flex-basis: 25%;
      `}
      &:nth-child(2n + 2) {
        border-left: 1px solid ${(props) => props.theme.navBorder};
      }

      &:nth-child(2) {
        border-bottom: 1px solid ${(props) => props.theme.navBorder};
        ${media.lg`
          border-bottom: 0;
          border-right: 1px solid ${(props) => props.theme.navBorder};
        `}
      }

      &:first-of-type {
        border-bottom: 1px solid ${(props) => props.theme.navBorder};
        ${media.lg`
          border-bottom: 0;
        `}
      }
      &:last-of-type {
        flex-grow: 1;

        ${media.lg`
          flex-grow: revert;

        `}
      }
      a {
        font-family: 'ProximaNova-Bold';
        font-size: 18px;
        display: inline-flex;
        color: ${(props) => props.theme.white};
        padding: 20px;
        text-decoration: none;
        transition: background-color 300ms ${customEase.easeIn};
        width: 100%;
        justify-content: center;
        align-items: center;

        &:hover {
          text-decoration: underline;
          background-color: ${(props) => props.theme.primaryHover};
        }
        &:active {
          background-color: ${(props) => props.theme.primaryHover};
        }
      }
    }
  }
`;

const Navigation = ({ navItems }) => {
  const [height, setHeight] = useState(0);
  const navContainer = useRef(null);

  useEffect(() => {
    setHeight(navContainer.current.clientHeight);
  }, []);
  return (
    <StyledNav
      className="container"
      ref={navContainer}
      navLength={navItems.length}
    >
      <ul>
        {navItems &&
          navItems.map((item, i) => {
            return (
              <li key={item + i}>
                <AnchorLink
                  offset={height}
                  href={`#${item.entity.fieldCaption}`}
                >
                  {item.entity.fieldTitle}
                </AnchorLink>
              </li>
            );
          })}
      </ul>
    </StyledNav>
  );
};

Navigation.propTypes = {
  navItems: PropTypes.array.isRequired,
};

export default Navigation;
