import React from 'react';
import styled from 'styled-components';

import { pulse } from '../../../utilities/animations.js';

const StyledImageLoader = styled.div`
  margin: 40px 0;
  overflow: hidden;
`;

const StyledImage = styled.div`
  height: 300px;
  width: 100%;
  animation: ${pulse} 1.5s linear infinite;
`;

const ImageLoader = () => {
  return (
    <StyledImageLoader>
      <StyledImage />
    </StyledImageLoader>
  );
};

export default ImageLoader;
