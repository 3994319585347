import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { slugify } from '../../../utilities/helpers.js';

const CTABanner = loadable(() => import('./CTABanner.js'));
const MediaBlock = loadable(() => import('./MediaBlock.js'));
const ParagraphAccordionGroup = loadable(() =>
  import('../../paragraphs/ParagraphAccordionGroup.js')
);
const CardCarousel = loadable(() => import('./CardCarousel/CardCarousel.js'));
const LogoStrip = loadable(() => import('./LogoStrip.js'));
const ParagraphVideo = loadable(() =>
  import('../../paragraphs/ParagraphVideo.js')
);
const ParagraphVideoGallery = loadable(() =>
  import('../../paragraphs/ParagraphVideoGallery.js')
);
const LazyImage = loadable(() => import('../../LazyImage.js'));
const FullWidthBlock = loadable(() =>
  import('../Paragraphs/FullWidthBlock.js')
);
const TextQuote = loadable(() => import('../Paragraphs/TextQuote.js'));
const QuoteBlock = loadable(() => import('../Paragraphs/QuoteBlock.js'));
const SloganBlock = loadable(() => import('../Paragraphs/SloganBlock.js'));
const ParagraphSocialMedia = loadable(() =>
  import('../../paragraphs/ParagraphSocialMedia.js')
);
const SocialTag = loadable(() => import('./SocialTag.js'));
const EventBlock = loadable(() => import('./EventBlock.js'));

const PARAGRAPH_MAPPING = {
  cta_banner: CTABanner,
  text_block: MediaBlock,
  accordion_group: ParagraphAccordionGroup,
  card_carousel: CardCarousel,
  logo_strip: LogoStrip,
  video_block: ParagraphVideo,
  video_gallery: ParagraphVideoGallery,
  image_block: LazyImage,
  full_width_block: FullWidthBlock,
  text_quote: TextQuote,
  // commented out until we need to use the image paragraph
  // image: LazyImage,
  full_width_block_image: LazyImage,
  text_block_with_quote: QuoteBlock,
  slogan_block: SloganBlock,
  social_media: ParagraphSocialMedia,
  social_tag: SocialTag,
  event_block: EventBlock,
};

/*
Check if we want the paragraph in a grid, add paragraph item to hasGrid array
*/
const hasGrid = [
  // Should the paragraph be wrapped in its own grid?
  'accordion_group',
  'logo_strip',
  'cta_banner',
  'video_gallery',
  'social_tag',
];

const notSection = [
  'video_block',
  'image_block',
  // commented out until we need to use the image paragraph
  // 'image',
  'text_quote',
  'full_width_block_image',
];

const Paragraph = ({
  type,
  values,
  hubName,
  className,
  context,
  campaignTheme,
  paragraphs,
  isCampaign,
  nodeId,
  hubPage,
}) => {
  // Find out which component we are dealing with from our mapping object
  const ParagraphComponent = PARAGRAPH_MAPPING[type];

  // Set up empty object to hold the values that we need to pass to the components
  let componentProps = {};

  const gridComponent = hasGrid.includes(type);

  const noSection = notSection.includes(type);

  // Each paragraph type will need it's own specific props.
  // By assigning them here it means that we can pass them directly
  // to the component as required rather than passing a massive 'values'
  // object that the component then has to figure out what to do with.
  // This help to keep the component reusable and less complicated
  switch (type) {
    case 'cta_banner':
      componentProps.title = values.fieldTitle;
      componentProps.introText = values.fieldDescription.processed;
      componentProps.ctaButton = values.fieldComponent
        ? values.fieldComponent.entity
        : null;
      break;
    case 'text_block':
      componentProps.title = values.textBlockTitle;
      componentProps.anchor = values.textBlockAnchor;
      componentProps.bodyText = values.textBlockContent.value;

      componentProps.buttonUrl = values.fieldComponent
        ? values.fieldComponent.entity.fieldLink.url.path
        : null;

      componentProps.buttonTitle = values.textBlockLink
        ? values.textBlockLink.entity.fieldLink.title
        : null;

      componentProps.openNewWindow = values.textBlockLink
        ? values.textBlockLink.entity.fieldBoolean
        : null;

      componentProps.alignment = values.textBlockAlignment;

      componentProps.paragraph = values.textBlockMedia;
      break;
    case 'accordion_group':
      componentProps.title = values.title ? values.title : null;
      componentProps.intro = values.intro ? values.intro.value : null;
      componentProps.anchor = values.anchor ? values.anchor : null;
      componentProps.accordions = values.accordions;
      break;
    case 'card_carousel':
      componentProps.title = values.title ? values.title : null;
      componentProps.intro = values.intro ? values.intro.value : null;
      componentProps.anchor = values.anchor ? values.anchor : null;
      componentProps.cardGroups = values.cardGroups ? values.cardGroups : null;
      componentProps.cardLink = values.entity ? values.entity : null;
      componentProps.hubName = hubName ? hubName : null;
      componentProps.isCampaign = true;
      break;
    case 'logo_strip':
      componentProps.title = values.title ? values.title : null;
      componentProps.intro = values.intro ? values.intro.value : null;
      componentProps.anchor = values.anchor ? values.anchor : null;
      componentProps.logosArray = values.fieldComponents
        ? values.fieldComponents
        : null;
      break;
    case 'video_block':
      componentProps.url = values.fieldVideo
        ? values.fieldVideo.entity.remoteUrl
        : null;

      componentProps.thumbnailUrl = values.fieldVideo.entity.thumbnail.url
        ? values.fieldVideo.entity.thumbnail.url
        : null;

      componentProps.videoCustomPlaceholder = values.fieldImage
        ? values.fieldImage.entity.fieldMediaImage.derivative.url
        : null;

      componentProps.title = values.fieldVideo.entity.title
        ? values.fieldVideo.entity.title
        : null;

      componentProps.mediaCaption = values.fieldCaption;
      componentProps.isCampaign = true;
      break;
    case 'image_block':
      componentProps.src = values.fieldImage
        ? values.fieldImage.entity.fieldMediaImage.derivative.url
        : null;

      componentProps.placeholder = values.fieldImage
        ? values.fieldImage.entity.fieldMediaImage.placeholder.url
        : null;
      componentProps.width = values.fieldImage ? 480 : null;
      componentProps.height = values.fieldImage ? 270 : null;

      componentProps.alt = values.fieldImage
        ? values.fieldImage.entity.fieldMediaImage.alt
        : null;

      componentProps.mediaCaption = values.fieldCaption;
      break;
    case 'social_media':
      componentProps.instagram = values.fieldInstagramAccount
        ? values.fieldInstagramAccount.entity.instagramAddress
        : null;
      componentProps.twitter = values.fieldTwitterAccount
        ? values.fieldTwitterAccount.entity.twitterAddress
        : null;
      componentProps.socialNetwork = values.fieldComponent
        ? values.fieldComponent.entity.fieldComponents
        : null;
      componentProps.isCampaign = true;
      break;
    case 'social_tag':
      componentProps.title = values.title ? values.title : null;
      componentProps.socialNetwork = values.fieldComponent
        ? values.fieldComponent.entity.fieldComponents
        : null;
      componentProps.hashLinkUrl = values.hashLink
        ? values.hashLink.url.path
        : null;
      componentProps.socialBlurb = values.fieldTextShort
        ? values.fieldTextShort
        : null;
      componentProps.hashLinkTitle = values.hashLink
        ? values.hashLink.hashTag
        : null;
      componentProps.hashTag = values.hashTag ? values.hashTag : null;
      componentProps.isCampaign = true;
      break;
    case 'video_gallery':
      componentProps.title = values.title ? values.title : null;
      componentProps.intro = values.intro ? values.intro.value : null;
      componentProps.anchor = values.anchor ? values.anchor : null;
      componentProps.videos = values.videos ? values.videos : null;
      componentProps.isCampaign = true;
      break;
    case 'full_width_block':
      componentProps.title = values.fieldTitle ? values.fieldTitle : null;

      componentProps.paragraphs = values.fieldUnlimitedComponents
        ? values.fieldUnlimitedComponents
        : null;
      break;
    case 'text_quote':
      componentProps.quote = values.fieldText ? values.fieldText.value : null;
      componentProps.quoteAuthor = values.fieldSubheading
        ? values.fieldSubheading
        : null;
      componentProps.citation = values.fieldTextShort
        ? values.fieldTextShort
        : null;
      componentProps.citationUrl = values.fieldUrl ? values.fieldUrl : null;
      componentProps.quoteAuthorRole = values.fieldTitle
        ? values.fieldTitle
        : null;

      break;
    // Image paragraph below is just commented out until we need to use it
    // case 'image':
    //   componentProps.src = values.fieldImage
    //     ? values.fieldImage.entity.fieldMediaImage.generic.url
    //     : null;
    //   componentProps.placeholder = values.fieldImage
    //     ? values.fieldImage.entity.fieldMediaImage.placeholder.url
    //     : null;
    //   componentProps.width = values.fieldImage
    //     ? values.fieldImage.entity.fieldMediaImage.generic.width
    //     : null;
    //   componentProps.height = values.fieldImage
    //     ? values.fieldImage.entity.fieldMediaImage.generic.height
    //     : null;
    //   componentProps.alt = values.fieldImage
    //     ? values.fieldImage.entity.fieldMediaImage.alt
    //     : null;
    //   break;
    case 'full_width_block_image':
      componentProps.src = values.fieldImage
        ? values.fieldImage.entity.fieldMediaImage.generic.url
        : null;
      componentProps.placeholder = values.fieldImage
        ? values.fieldImage.entity.fieldMediaImage.placeholder.url
        : null;
      componentProps.width = values.fieldImage
        ? values.fieldImage.entity.fieldMediaImage.generic.width
        : null;
      componentProps.height = values.fieldImage
        ? values.fieldImage.entity.fieldMediaImage.generic.height
        : null;
      componentProps.alt = values.fieldImage
        ? values.fieldImage.entity.fieldMediaImage.alt
        : null;
      break;
    case 'text_block_with_quote':
      componentProps.quote = values.fieldTitle;
      componentProps.quoteAuthorRole = values.fieldCaption;
      componentProps.citation = values.fieldTextShort;
      componentProps.author = values.fieldSubheading;
      componentProps.citationUrl = values.fieldLink
        ? values.fieldLink.url.path
        : null;
      componentProps.quoteImageAlt = values.fieldHighlightImage
        ? values.fieldHighlightImage.entity.fieldMediaImage.alt
        : null;
      componentProps.highlightImage = values.fieldHighlightImage
        ? values.fieldHighlightImage.entity.fieldMediaImage.derivative.url
        : null;
      break;
    case 'slogan_block':
      componentProps.slogan = values.fieldTitle;
      break;
    case 'event_block':
      componentProps.title = values.title;
      componentProps.nodeId = nodeId;
      componentProps.hubName = hubName ? hubName : null;
      componentProps.featuredCardImage = values.card
        ? values.card.entity.image.entity.fieldMediaImage.generic.url
        : null;

      componentProps.featuredCardImageWidth = values.card
        ? values.card.entity.image.entity.fieldMediaImage.generic.width
        : null;

      componentProps.featuredCardImageHeight = values.card
        ? values.card.entity.image.entity.fieldMediaImage.generic.height
        : null;

      componentProps.featuredCardPlaceholderImage = values.card
        ? values.card.entity.image.entity.fieldMediaImage.placeholder.url
        : null;

      componentProps.featuredCardIntro = values.card
        ? values.card.entity.intro.value
        : null;

      componentProps.featuredCardTitle = values.card
        ? values.card.entity.title
        : null;

      componentProps.featuredCardNewWindow = values.newWindow;
      componentProps.featuredCardLink = values.card
        ? values.card.entity.eventLink.path
        : null;
      componentProps.eventStart = values.card
        ? values.card.entity.eventStart.value
        : null;
      componentProps.eventEnd = values.card
        ? values.card.entity.eventEnd.value
        : null;
      componentProps.anchor = values.anchor ? values.anchor : null;
      componentProps.isHubPage = hubPage ? true : false;
      componentProps.allDayEvent = values.card.entity.allDayEvent;

      break;
    default:
      break;
  }
  return (
    <>
      {/* Check if we want the paragraph in a grid, add paragraph item to hasGrid array */}
      {gridComponent ? (
        <section
          className={noSection ? '' : 'paragraph-section'}
          id={values.anchor ? slugify(values.anchor) : ''}
        >
          <Grid>
            <Row>
              <Col xs>
                <ParagraphComponent
                  className={className}
                  {...componentProps}
                  context={context}
                  campaignTheme={campaignTheme}
                />
              </Col>
            </Row>
          </Grid>
        </section>
      ) : noSection ? (
        <>
          <ParagraphComponent
            className={className}
            {...componentProps}
            context={context}
            campaignTheme={campaignTheme}
          />
        </>
      ) : (
        <>
          <section
            className={noSection ? '' : 'paragraph-section'}
            id={values.anchor ? slugify(values.anchor) : ''}
          >
            <ParagraphComponent
              className={className}
              {...componentProps}
              context={context}
              campaignTheme={campaignTheme}
            />
          </section>
        </>
      )}
    </>
  );
};

export default Paragraph;

Paragraph.propTypes = {
  type: PropTypes.string,
  values: PropTypes.object,
  hubName: PropTypes.string,
  className: PropTypes.string,
  context: PropTypes.string,
  campaignTheme: PropTypes.object,
  paragraphs: PropTypes.object,
  isCampaign: PropTypes.bool,
  nodeId: PropTypes.string,
  hubPage: PropTypes.string,
};
