import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';

import FooterContact from '../Footer/FooterContact.js';
import UsefulLinks from './UsefulLinks.js';

const StyledFooter = styled.footer`
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.globalPrimary};
  padding-bottom: 40px;
  font-family: 'ProximaNova-Regular';
  a {
    color: ${(props) => props.theme.white};
    &:hover {
      text-decoration: underline;
    }
  }
`;

// Default theme values
StyledFooter.defaultProps = {
  theme: {
    globalPrimary: 'rgba(57, 3, 59, 1)',
    white: 'rgba(255,255,255, 1)',
  },
};

const Footer = ({ footerContactData }) => {
  // Function to check through the values of the footer fields to see if we have any data to output.
  let isEmpty = true;

  const fields = [
    'footerEmail',
    'footerLocation',
    'footerMapLink',
    'footerPhone',
    'footerTitle',
  ];

  if (footerContactData) {
    for (var i = 0; i < fields.length; i++) {
      if (footerContactData[fields[i]] !== null) {
        isEmpty = false;
      }
    }
  }

  return (
    <StyledFooter role="contentinfo">
      <Grid>
        <Row>
          {/* // If we dont have any footer data just output useful links  */}
          {isEmpty ? (
            <Col xs>
              <UsefulLinks />
            </Col>
          ) : (
            <>
              <Col xs={12} md={5}>
                <FooterContact
                  footerContactData={footerContactData}
                ></FooterContact>
              </Col>
              <Col xs={12} md={7}>
                <UsefulLinks />
              </Col>
            </>
          )}
        </Row>
      </Grid>
    </StyledFooter>
  );
};

Footer.propTypes = {
  footerContactData: PropTypes.object.isRequired,
};

export default Footer;
