import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

const StyledCustomCol = styled(Col)`
  padding: 0;
`;

const CustomCol = ({ children, ...rest }) => {
  return <StyledCustomCol {...rest}>{children}</StyledCustomCol>;
};

export default CustomCol;

CustomCol.propTypes = {
  children: PropTypes.node.isRequired,
};
