import React from 'react';
import PropTypes from 'prop-types';

const CampaignLogo = ({ url, alt }) => {
  // Set field to variable to save doing so in the template

  return (
    <>
      {/* If we have a logo show it if not  */}
      {url && <img src={url} alt={alt} width="auto" height="66" />}
    </>
  );
};

CampaignLogo.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default CampaignLogo;
