import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from '../../../utilities/breakpoints.js';

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 25px 0;
  ${media.lg`
    margin: 10px 0;
  `}
`;

const StyledText = styled.span`
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
  font-size: 20px;
  font-family: 'ProximaNova-Bold';
  padding: 5px;
  ${media.lg`
    font-size: 28px;
  `}
`;

export const TextBlock = ({ bannerTextBlock }) => {
  return (
    <StyledFlexContainer>
      <StyledText>{bannerTextBlock}</StyledText>
    </StyledFlexContainer>
  );
};

TextBlock.propTypes = {
  bannerTextBlock: PropTypes.string.isRequired,
};

export default TextBlock;
