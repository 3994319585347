import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Title from '../Common-components/Title/Title.js';

import { customEase } from '../../../utilities/transitions.js';

const StyledFooterHeading = styled(Title)`
  font-size: 27px;
  padding: 32px 0 10px;
  margin: 0;
`;

const StyledFooterLink = styled.a`
  display: inline-flex;
  align-self: flex-start;
  text-decoration: none;
  transition: all 300ms ${customEase.easeIn};
  margin: 5px 0;
  text-decoration: none;
  font-size: 18px;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
`;

const StyledAddress = styled.address`
  white-space: pre;
  padding: 5px 0;
  line-height: 27px;
  display: inline-flex;
  align-self: flex-start;
  text-decoration: none;
  font-style: normal;
  font-size: 18px;
`;

const FooterContact = ({ footerContactData }) => {
  return (
    <>
      {footerContactData.footerTitle && (
        <StyledFooterHeading>
          {footerContactData.footerTitle}
        </StyledFooterHeading>
      )}
      {footerContactData.footerMapLink && footerContactData.footerLocation && (
        <StyledFooterLink
          href={footerContactData.footerMapLink.url.path}
          target={footerContactData.openNewWindow ? '_blank' : '_self'}
          title={`Directions to ${footerContactData.footerLocation}`}
        >
          <StyledAddress
            dangerouslySetInnerHTML={{
              __html: footerContactData.footerLocation,
            }}
          />
        </StyledFooterLink>
      )}
      {!footerContactData.footerMapLink && footerContactData.footerLocation && (
        <StyledAddress
          dangerouslySetInnerHTML={{
            __html: footerContactData.footerLocation,
          }}
        />
      )}
      {(footerContactData.footerPhone || footerContactData.footerEmail) && (
        <StyledFlexContainer>
          {footerContactData.footerPhone && (
            <StyledFooterLink
              href={`tel: ${footerContactData.footerPhone}`}
              title={`Call us on ${footerContactData.footerPhone}`}
            >
              {footerContactData.footerPhone}
            </StyledFooterLink>
          )}
          {footerContactData.footerEmail && (
            <StyledFooterLink
              href={`mailto: ${footerContactData.footerEmail}`}
              title={`E-mail us on ${footerContactData.footerEmail}`}
            >
              {footerContactData.footerEmail}
            </StyledFooterLink>
          )}
        </StyledFlexContainer>
      )}
    </>
  );
};

FooterContact.propTypes = {
  footerContactData: PropTypes.object.isRequired,
};

export default FooterContact;
