import { keyframes } from 'styled-components';
import colors from './config/colors.js';

/**
|--------------------------------------------------
| Where we list all of our animations to be used
| within the app. Add keyframes here and then import
| using named imports to the component that you wish
| to animate.
|--------------------------------------------------
*/

export const rotate90 = keyframes`
  from {
    transform: rotate(0deg);
    opacity: 1;
  }

  to {
    transform: rotate(90deg);
    opacity: 0;
  }
`;

export const rotate90reverse = keyframes`

  from {
    transform: rotate(90deg);
    opacity: 0;
  }

  to {
    transform: rotate(0deg);
    opacity: 1;
  }
`;

export const fadein = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const pulse = keyframes`
  from {
    background-color: ${colors.vapor};
  }

  50% {
    background-color: ${colors.cascadingWhite};
  }

  100% {
    background-color: ${colors.vapor};
  }
`;

export const pulseDark = keyframes`
  from {
    background-color: ${colors.deepPurple50};
  }

  50% {
    background-color: ${colors.kuwanomiPurple};
  }

  100% {
    background-color: ${colors.deepPurple50};
  }
`;

export const pulseSecondary = keyframes`
  from {
    background-color: ${colors.calmWaters};
  }

  50% {
    background-color: ${colors.cascadingWhite};
  }

  100% {
    background-color: ${colors.calmWaters};
  }
`;

export const pulseSecondaryDark = keyframes`
  from {
    background-color: ${colors.americanPurple};
  }

  50% {
    background-color: ${colors.curedEggPlant};
  }

  100% {
    background-color: ${colors.americanPurple};
  }
`;

export const arrowAnimation = keyframes`
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100px);
  }
`;

export const fancyAnimationScale = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(31);
  }
`;

export default keyframes;
