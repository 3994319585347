/**
 * Gets a cookie value from a provided cookie name
 *
 * @param {string} cookieName The name of the cookie that you want to get the value for
 * @returns {string} The value of the requested cookie
 */
const getCookie = (cookieName) => {
  // If no cookieName is provided then exit early
  if (!cookieName) return;

  // Append '=' to the end of our desired cookieName
  const name = cookieName + '=';

  // Decode cookies for safety
  const cookiesDecoded = decodeURIComponent(document.cookie);

  // Split decoded cookies into an array
  const cookiesArray = cookiesDecoded.split('; ');

  // Set empty variable to put cookie value into
  let res;

  // Loop over the cookies array
  // If we find our desired cookie then we use substring to get
  // whatever is after the '=' character that we appended earlier
  // This will give us the value of the cookie
  cookiesArray.forEach((val) => {
    if (val.indexOf(name) === 0) {
      res = val.substring(name.length);
    }
  });

  return res;
};

export default getCookie;
