import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Grid, Row } from 'react-flexbox-grid';

import Button from '../components/Button.js';
import media from '../utilities/breakpoints.js';

const StyledCookieText = styled.p`
  color: ${(props) => props.theme.primary};
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  ${media.lg`
    font-size: 16px;
  `}
`;

const StyledCookieBanner = styled.div`
  align-items: center;
  background: ${(props) => props.theme.secondary};
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;

  z-index: 9999999999;
  bottom: 0px;
  padding: 20px;
  box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 100%;
`;

const CookieBanner = ({ cookieExists, className }) => {
  const [showBanner, setShowBanner] = useState(true);

  const acceptCookie = () => {
    const domainName = window.location.hostname.replace(/^[^.]+\./g, '');

    document.cookie = `mySurreyUserAcceptCookies=true; max-age=31536000; domain=${domainName}`;

    setShowBanner(false);
  };

  return (
    <>
      {showBanner ? (
        <StyledCookieBanner>
          <Grid>
            <Row middle="xs">
              <Col sm={12} md={8}>
                <StyledCookieText>
                  We use cookies to help our site work, to understand how it is
                  used, and to tailor ads that are more relevant to you and your
                  interests.
                </StyledCookieText>

                <StyledCookieText>
                  By accepting, you agree to cookies being stored on your
                  device.
                </StyledCookieText>
              </Col>

              <Col xs={12} md={4}>
                <Button
                  as={'button'}
                  to={'/'}
                  primary={'true'}
                  onClick={acceptCookie}
                  flexCenter
                >
                  Accept
                </Button>
              </Col>
            </Row>
          </Grid>
        </StyledCookieBanner>
      ) : null}
    </>
  );
};

CookieBanner.propTypes = {
  cookieExists: PropTypes.bool,
  className: PropTypes.string,
};

export default CookieBanner;
