import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import CopyLoader from '../Loaders/Components/CopyLoader.js';
import ImageLoader from '../Loaders/Components/ImageLoader.js';

const HubLandingPageLoader = () => {
  return (
    <>
      <Grid>
        <Row center="xs">
          <Col xs={12} lg={8}>
            <CopyLoader />
            <ImageLoader />
            <CopyLoader />
          </Col>
        </Row>
      </Grid>
    </>
  );
};

export default HubLandingPageLoader;
