import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';

import HomeLink from '../../TopBar/HomeLink.js';
import CampaignLogo from '../../Campaign/Header/CampaignLogo.js';
import media from '../../../utilities/breakpoints.js';

const StyledBackground = styled.header`
  background-color: ${(props) => props.theme.globalPrimary};
  padding: 6px 0;
`;

const StyledPadding = styled.div`
  margin-left: 20px;
  ${media.sm`
      margin-left: 0;
    `}
`;

const StyledRow = styled(Row)`
  min-height: 60px;
`;

// Default theme values
StyledBackground.defaultProps = {
  theme: {
    globalPrimary: 'rgba(57, 3, 59, 1)',
  },
};

const Header = ({ logo }) => {
  return (
    <StyledBackground role="banner">
      <Grid>
        <StyledRow between="xs" end="xs" middle="xs">
          <Col xs={3}>
            <StyledPadding>
              <HomeLink />
            </StyledPadding>
          </Col>
          <Col xs={9}>
            {logo && (
              <CampaignLogo
                url={logo.entity.fieldMediaImage1.campaignlogo.url}
                alt={logo.entity.fieldMediaImage1.alt}
                context="CampaignTheme"
              />
            )}
          </Col>
        </StyledRow>
      </Grid>
    </StyledBackground>
  );
};

Header.propTypes = {
  logo: PropTypes.object,
};

export default Header;
