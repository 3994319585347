export const hubLocations = {
  MySurrey: process.env.REACT_APP_MYSURREY,
  Accommodation: process.env.REACT_APP_ACCOMMODATION,
  'Campus life': process.env.REACT_APP_CAMPUS_LIFE,
  'Employability and careers': process.env.REACT_APP_EMPLOYABILITY,
  'Exams and assessments': process.env.REACT_APP_EXAMS_ASSESSMENTS,
  'Get involved': process.env.REACT_APP_GET_INVOLVED,
  Graduation: process.env.REACT_APP_GRADUATION,
  'Visas and immigration': process.env.REACT_APP_VISAS_IMMIGRATION,
  IT: process.env.REACT_APP_IT,
  Library: process.env.REACT_APP_LIBRARY_LEARNING_CENTRE,
  Money: process.env.REACT_APP_MONEY,
  Policies: process.env.REACT_APP_POLICIES,
  Research: process.env.REACT_APP_RESEARCH,
  Study: process.env.REACT_APP_STUDY,
  Transport: process.env.REACT_APP_TRANSPORT,
  Wellbeing: process.env.REACT_APP_WELLBEING,
};
