const SUBDOMAIN_MAPPING = {
  accommodation: 'Accommodation',
  'campus-life': 'Campus life',
  'employability-and-careers': 'Employability and careers',
  'exams-and-assessments': 'Exams and assessments',
  'get-involved': 'Get involved',
  graduation: 'Graduation',
  it: 'IT',
  'library-and-learning-centre': 'Library',
  money: 'Money',
  mysurrey: 'MySurrey',
  policies: 'Policies',
  research: 'Research',
  study: 'Study',
  transport: 'Transport',
  'visas-and-immigration': 'Visas and immigration',
  wellbeing: 'Wellbeing',
};

export default SUBDOMAIN_MAPPING;
