import { hubLocations } from './hubLocations.js';
import { hubInternalLocations } from './hubInternalLocations.js';

export default function subdomainLinker(url, currentHub, linkHub) {
  let internal, finalUrl;

  if (url) {
    // Does the link point to a page inside the current hub?
    // If it does, then we want to mark this link as internal
    // If it doesn't, we need to check whether the link points
    // to a different hub, or off site.
    // This allows us to set target="_blank" etc. for offsite links.
    const internalCheck = url.startsWith(hubInternalLocations[currentHub]);

    if (internalCheck) {
      internal = true;
      finalUrl = url.replace(hubInternalLocations[currentHub], '');
    } else {
      // If the url is not internal then we need to figure out if the link
      // if offsite or not.
      internal = false;
      finalUrl = linkHub
        ? `${hubLocations[linkHub]}${url.replace(
            hubInternalLocations[linkHub],
            ''
          )}`
        : url;
    }
  }
  if (finalUrl === '-hive') {
    finalUrl = 'hive';
  }
  return { internal, finalUrl };
}
