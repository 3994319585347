import gql from 'graphql-tag';

export const FOOTER_MENU_QUERY = gql`
  query {
    menu: menuByName(name: "footer-useful-links") {
      links {
        label
        description
        url {
          path
        }
      }
    }
  }
`;

export default FOOTER_MENU_QUERY;
