import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Header from '../Header.js';

const QueryError = () => {
  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <Header as="h1">Something went wrong</Header>
        </Col>
      </Row>
    </Grid>
  );
};

export default QueryError;
