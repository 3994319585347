import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ChevronIcon from '../../Icons/ChevronIcon.js';

import { customEase } from '../../../utilities/transitions.js';
import media from '../../../utilities/breakpoints.js';

const StyledButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.attention};
  border: ${(props) =>
    props.darkBackground ? '1px solid transparent' : 'none'};
  color: ${(props) => props.theme.buttonText};
  padding: 10px 5px 10px 15px;
  font-size: 22px;
  transition: background-color 300ms ${customEase.easeIn};
  text-decoration: none;
  max-width: 278px;
  margin-bottom: 30px;
  align-items: center;

  span {
    transition: color 300ms ${customEase.easeIn};
  }

  svg {
    polygon {
      transition: fill 300ms ${customEase.easeIn};
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) =>
      props.darkBackground
        ? (props) => props.theme.primary
        : (props) => props.theme.primaryHover};
    border: ${(props) => (props.darkBackground ? '1px solid white' : 'none')};
    > span {
      color: ${(props) => props.theme.white};
    }
    svg {
      polygon {
        fill: ${(props) => props.theme.white};
      }
    }
  }
`;

const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
  padding-right: 10px;
  font-size: 22px;
  font-family: ProximaNova-Semibold;
  color: ${(props) => props.theme.primary};
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${media.lg`
    justify-content: ${(props) =>
      props.centerButton ? 'center' : 'flex-start'};
 `}
`;

const Button = ({
  buttonText,
  campaignTheme,
  className,
  buttonUrl,
  openNewWindow,
  darkBackground,
  showChevron = true,
  // Center button only works at desktop. All mobile designs have centered buttons
  centerButton = true,
}) => {
  return (
    <StyledContainer centerButton={centerButton}>
      <StyledButton
        darkBackground={darkBackground}
        href={buttonUrl}
        title={buttonText}
        target={openNewWindow ? '_blank' : '_self'}
        rel={openNewWindow ? 'noopener' : ''}
        className={className}
      >
        <StyledSpan>{buttonText}</StyledSpan>

        {showChevron && (
          <ChevronIcon
            orientation="right"
            width="32"
            height="32"
            fatChevron
            fill={campaignTheme.primary}
          />
        )}
      </StyledButton>
    </StyledContainer>
  );
};

Button.propTypes = {
  buttonText: PropTypes.string.isRequired,
  campaignTheme: PropTypes.object.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  openNewWindow: PropTypes.bool,
  darkBackground: PropTypes.bool,
  showChevron: PropTypes.bool,
  centerButton: PropTypes.bool,
};

export default Button;
