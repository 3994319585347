import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Button from '../Button/Button.js';

import Paragraph from '../Paragraphs/Paragraph.js';
import TextBlock from '../TextBlock/TextBlock.js';
import Title from '../Common-components/Title/Title.js';

import media from '../../../utilities/breakpoints.js';
import { transitionAll } from '../../../utilities/transitions.js';

const StyledCampaignBanner = styled.div`
  ${(props) =>
    (props.childGradient &&
      css`
        background: linear-gradient(
          to left,
          ${(props) => props.theme.gradientOne} 40%,
          ${(props) => props.theme.gradientTwo}
        );
      `) ||
    `background: white;`}
  padding: 10px;
  ${media.lg`
    padding: 10px 10px 20px;
  `}
`;

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) =>
    props.childGradient ? props.theme.white : props.theme.bannerText};
  ${media.lg`
    margin-top: 15px;
    flex-basis: ${(props) => (props.noMedia ? '70%' : '50%')};
  `}
`;

const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.lg`
    align-items: flex-start;
    padding-right: 10px;
  `}
`;

const StyledBannerTag = styled.a`
  background-color: ${(props) => props.theme.primaryHover};
  transition: ${transitionAll.transition};
  color: ${(props) => props.theme.white};
  padding: 10px 20px;
  text-transform: uppercase;
  display: inline-flex;
  text-decoration: none;
  span {
    font-size: 18px;
  }
  &:hover {
    background-color: ${(props) => props.theme.primary};
  }
`;

const StyledBannerTagSpan = styled.span`
  background-color: ${(props) => props.theme.primaryHover};
  color: ${(props) => props.theme.white};
  padding: 10px 20px;
  text-transform: uppercase;
  display: inline-flex;
  text-decoration: none;
  font-size: 18px;
`;

const StyledHeading = styled(Title)`
  color: ${(props) =>
    props.childGradient ? props.theme.attention : props.theme.primary};
  padding: 0;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  margin-top: 15px;
  ${media.lg`
    text-align: left;
    font-size: 60px;
    line-height: 60px;
    margin-top: 0;
  `}
`;

const StyledStrapline = styled.h2`
  color: ${(props) =>
    props.childGradient ? props.theme.bannerText : props.theme.primary};
  font-size: 22px;
  font-weight: bold;
  margin-top: 30px;
  text-align: center;
  ${media.lg`
    font-size: 32px;
    text-align: left;
  `}
`;

const StyledBlurb = styled.div`
  margin: 5px 0 15px;
  text-align: center;
  p {
    color: ${(props) =>
      props.childGradient ? props.theme.bannerText : props.theme.primary};
    font-size: 18px;
    line-height: normal;
    ${media.lg`
      font-size: 24px;
      text-align: left;
    `}
  }
`;

const StyledFlexContainerRow = styled.div`
  ${media.lg`
    display: flex;
    flex-direction: row;
  `}
`;

const StyledFlexContainerColumn = styled.div`
  ${media.lg`
    margin-top: 15px;
    flex-basis: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;

const Banner = ({
  bannerTagLink,
  bannerTagText,
  bannerTitle,
  bannerStrap,
  bannerIntro,
  bannerButton,
  bannerTextBlock,
  campaignTheme,
  childGradient,
  paragraph,
}) => {
  // Check if we have data for the components if not set to null
  bannerButton = bannerButton ? bannerButton.button : null;
  return (
    <StyledCampaignBanner childGradient={childGradient}>
      <Grid>
        <Row>
          <Col xs={12}>
            {/* Check if there is a bannerTag if so show it */}
            {bannerTagLink && bannerTagText ? (
              <StyledBannerTag
                href={bannerTagLink.link.path}
                title={bannerTagText}
              >
                <span>{bannerTagText}</span>
              </StyledBannerTag>
            ) : (
              bannerTagText !== null && (
                <StyledBannerTagSpan>{bannerTagText}</StyledBannerTagSpan>
              )
            )}
            <StyledFlexContainerRow>
              <StyledFlexContainer
                childGradient={childGradient}
                noMedia={paragraph === null}
              >
                <StyledFlexColumn noMedia={paragraph === null}>
                  <StyledHeading as="h1" childGradient={childGradient}>
                    {bannerTitle}
                  </StyledHeading>
                  <StyledStrapline childGradient={childGradient}>
                    {bannerStrap}
                  </StyledStrapline>
                  <StyledBlurb
                    childGradient={childGradient}
                    dangerouslySetInnerHTML={{
                      __html: bannerIntro.processed,
                    }}
                  ></StyledBlurb>
                  {/* Check if there is a button if so show it */}
                  {bannerButton && (
                    <Button
                      buttonText={bannerButton.fieldLink.title}
                      campaignTheme={campaignTheme}
                      buttonUrl={bannerButton.fieldLink.url.path}
                      openNewWindow={bannerButton.openNewWindow}
                      darkBackground={true}
                    />
                  )}
                </StyledFlexColumn>
              </StyledFlexContainer>
              {/* Check if there is a video or image if so show it */}

              <StyledFlexContainerColumn>
                {paragraph && (
                  <Paragraph
                    type={paragraph.paragraphType.targetId}
                    values={paragraph}
                    key={paragraph.uuid}
                    context="CampaignTheme"
                    campaignTheme={campaignTheme}
                    isCampaign={true}
                  />
                )}
                {/* Text block is output twice because if there is no image we dont want the textblock to be contained by a flex container */}
                {bannerTextBlock && (
                  <TextBlock bannerTextBlock={bannerTextBlock}></TextBlock>
                )}
              </StyledFlexContainerColumn>
            </StyledFlexContainerRow>
          </Col>
        </Row>
      </Grid>
    </StyledCampaignBanner>
  );
};

Banner.propTypes = {
  bannerTagLink: PropTypes.object,
  bannerTagText: PropTypes.string,
  bannerTitle: PropTypes.string.isRequired,
  bannerStrap: PropTypes.string,
  bannerIntro: PropTypes.object.isRequired,
  bannerButton: PropTypes.object,
  bannerTextBlock: PropTypes.string,
  campaignTheme: PropTypes.object.isRequired,
  childGradient: PropTypes.bool,
  paragraph: PropTypes.object,
};

export default Banner;
