import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from '@reach/router';

import QueryError from '../components/Error/QueryError.js';

import FOOTER_MENU_QUERY from '../queries/FooterMenuQuery.js';

import media from '../utilities/breakpoints.js';
import SUBDOMAIN_MAPPING from '../utilities/subdomainMapping.js';
import subdomainLinker from '../utilities/subdomainLinker.js';

const StyledFooter = styled.footer`
  padding: 40px 0 20px;
  background-color: ${(props) => props.theme.globalPrimary};
  color: ${(props) => props.theme.white};
`;

const StyledFooterContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  ${media.lg`
    flex-direction: row;
  `}
`;

const StyledFooterText = styled.small`
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.cascadingWhite};
`;

const StyledGlobalLinkList = styled.ul`
  list-style-type: none;

  ${media.md`
    display: flex;
    justify-content: flex-start;
    padding: 0;
    flex-wrap: wrap;
  `}

  li:first-child {
    ${media.md`
      padding-left:0;
    `}
  }
  li:last-child {
    &::after {
      ${media.md`
        display: none;
      `}
    }
  }
`;

const StyledFlexWrapper = styled.div`
  ${media.md`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `}
`;

const StyledFooterBlock = styled.div`
  ${media.md`
    flex-basis: 50%;
  `}
  ${media.lg`
    flex-basis: 25%;
  `}
  a {
    color: ${(props) => props.theme.white};
  }
`;

const StyledFooterHeader = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.white};
  font-family: 'ProximaNova-Semibold';
  font-size: 1.625rem;
  color: ${(props) => props.theme.white};
  padding: 0 40px;
  ${media.md`
    padding: 0 ;
    display: inline-block;
    margin: 0;
  `}
  ${media.lg`
    border-bottom: none;
  `}
`;

const StyledGlobalFooterHeader = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.white};
  font-family: 'ProximaNova-Semibold';
  font-size: 1.625rem;
  color: ${(props) => props.theme.white};
  padding: 0 40px;

  ${media.md`
    display: inline-block;
    padding: 0;
    margin: 10px 0;
  `}

  ${media.lg`
    display:none;
  `}
`;

const StyledFooterLinkContainer = styled.ul`
  list-style: none;
  ${media.md`
    padding: 0;
  `}
`;

const StyledFooterItem = styled.li`
  line-height: 1.61;
  width: 95%;
`;

const StyledFooterLinkExternal = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.white};

  &:hover {
    color: ${(props) => props.theme.secondary};
    text-decoration: underline;
  }
`;

const StyledFooterLinkInternal = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.white};

  &:hover {
    color: ${(props) => props.theme.secondary};
    text-decoration: underline;
  }
`;

const StyledGlobalItem = styled.li`
  line-height: 1.61;

  ${media.md`
    display: flex;
    justify-content: center;
    padding: 0 15px;
    position: relative;
  `}
  &::after {
    ${media.md`
      content: '|';
      display: inline-flex;
      width: 1px;
      height: 10px;
      position: absolute;
      right: 0;
    `}
  }
`;

const StyledGlobalLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.white};
  line-height: 1.61;
  display: block;
  &:hover {
    color: ${(props) => props.theme.secondary};
    text-decoration: underline;
  }
`;

const Footer = ({ hubName }) => {
  return (
    <>
      <Query query={FOOTER_MENU_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <div>...Loading menu</div>;

          if (error) return <QueryError />;

          const currentYear = new Date();

          return (
            <StyledFooter>
              <Grid>
                <Row>
                  <Col xs={12}>
                    <StyledFlexWrapper>
                      {data.menu.links &&
                        data.menu.links.map((link) => (
                          <StyledFooterBlock key={`footer-group-${link.label}`}>
                            <StyledFooterHeader>
                              {link.label}
                            </StyledFooterHeader>
                            {link.links.length > 0 && (
                              <StyledFooterLinkContainer>
                                {link.links.map((subLink, i) => {
                                  const currentHub = hubName;
                                  const url = subLink.url.path;

                                  const subdomainSplit = url.split('/', 2);
                                  const subdomain = subdomainSplit[1];
                                  let finalSubdomain =
                                    SUBDOMAIN_MAPPING[subdomain];

                                  const linkHub = finalSubdomain;

                                  const { internal, finalUrl } =
                                    subdomainLinker(url, currentHub, linkHub);

                                  return (
                                    <StyledFooterItem
                                      key={`footer-sublink-${subLink.label}`}
                                    >
                                      {internal ? (
                                        <StyledFooterLinkInternal
                                          to={finalUrl}
                                          title={subLink.description}
                                        >
                                          {subLink.label}
                                        </StyledFooterLinkInternal>
                                      ) : (
                                        <StyledFooterLinkExternal
                                          href={finalUrl}
                                          title={subLink.description}
                                        >
                                          {subLink.label}
                                        </StyledFooterLinkExternal>
                                      )}
                                    </StyledFooterItem>
                                  );
                                })}
                              </StyledFooterLinkContainer>
                            )}
                          </StyledFooterBlock>
                        ))}
                    </StyledFlexWrapper>
                    <StyledGlobalFooterHeader>
                      Other Information
                    </StyledGlobalFooterHeader>

                    <StyledGlobalLinkList>
                      <StyledGlobalItem>
                        <StyledGlobalLink
                          href="https://www.surrey.ac.uk/accessibility-statement-mysurrey"
                          title="University of Surrey Accessibility statement"
                        >
                          Accessibility statement
                        </StyledGlobalLink>
                      </StyledGlobalItem>
                      <StyledGlobalItem>
                        <StyledGlobalLink
                          href="https://my.surrey.ac.uk/cookies"
                          title="Find out how Surrey University uses cookies"
                        >
                          Cookies
                        </StyledGlobalLink>
                      </StyledGlobalItem>
                      <StyledGlobalItem>
                        <StyledGlobalLink
                          href="https://www.surrey.ac.uk/disclaimer"
                          title="Unversity of surrey disclaimer"
                        >
                          Disclaimer
                        </StyledGlobalLink>
                      </StyledGlobalItem>
                      <StyledGlobalItem>
                        <StyledGlobalLink
                          href="https://www.surrey.ac.uk/information-management/freedom-information"
                          title="Find out about freedom of information at the University of Surrey."
                        >
                          Freedom of information
                        </StyledGlobalLink>
                      </StyledGlobalItem>
                      <StyledGlobalItem>
                        <StyledGlobalLink
                          href="https://www.surrey.ac.uk/information-management/data-protection"
                          title="University of Surrey data protection"
                        >
                          Privacy
                        </StyledGlobalLink>
                      </StyledGlobalItem>
                    </StyledGlobalLinkList>
                  </Col>
                  <Col xs={10}>
                    <StyledFooterContent>
                      <StyledFooterText>
                        &copy; {`University of Surrey `}
                        {currentYear.getFullYear()}
                      </StyledFooterText>
                    </StyledFooterContent>
                  </Col>
                </Row>
              </Grid>
            </StyledFooter>
          );
        }}
      </Query>
    </>
  );
};

export default Footer;

Footer.propTypes = {
  hubName: PropTypes.string.isRequired,
};
