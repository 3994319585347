import TagManager from 'react-gtm-module';

export function trackGTM(pageTitle, pageUrl) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'PageView',
      pageTitle: pageTitle,
      pageUrl: pageUrl,
    },
    dataLayerName: 'PageDataLayer',
  });
}
