export const hubInternalLocations = {
  Accommodation: '/accommodation',
  'Campus life': '/campus-life',
  'Employability and careers': '/employability-and-careers',
  'Exams and assessments': '/exams-and-assessments',
  'Get involved': '/get-involved',
  Graduation: '/graduation',
  IT: '/it',
  Library: '/library-and-learning-centre',
  Money: '/money',
  MySurrey: '/mysurrey',
  Policies: '/policies',
  Research: '/research',
  Study: '/study',
  Transport: '/transport',
  'Visas and immigration': '/visas-and-immigration',
  Wellbeing: '/wellbeing',
};
