import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@reach/router';

import UniOfSurreyIcon from '../Icons/UniOfSurreyIcon.js';
import StagIcon from '../Icons/StagIcon.js';

import { hubLocations } from '../../utilities/hubLocations.js';
import media from '../../utilities/breakpoints.js';

const StyledSurreyLogo = styled(UniOfSurreyIcon)`
  display: none;

  ${media.md`
    display: block;
  `}

  path {
    fill: white;
  }
`;

const StyledStagLogo = styled(StagIcon)`
  display: block;

  ${media.md`
    display: none;
  `}

  path {
    fill: white;
  }
`;

const HomeLink = ({ hubName }) => {
  return (
    <>
      {/* Check if we are in a MySurrey hub */}
      {hubName === 'MySurrey' ? (
        <Link
          aria-label="MySurrey homepage"
          title="MySurrey"
          to={'/'}
          className="homeLink"
        >
          <StyledSurreyLogo />
          <StyledStagLogo />
        </Link>
      ) : (
        <a
          aria-label="MySurrey homepage"
          title="MySurrey"
          href={hubLocations['MySurrey']}
          className="homeLink"
        >
          {/* We output both logos but depending on screen size only show either or */}
          <StyledSurreyLogo />
          <StyledStagLogo />
        </a>
      )}
    </>
  );
};

export default HomeLink;

HomeLink.propTypes = {
  hubName: PropTypes.string,
};
