import React from 'react';
import styled from 'styled-components';

import { pulse } from '../../../utilities/animations.js';

const StyledCopyLoader = styled.div`
  margin: 40px 0;
  overflow: hidden;
`;

const StyledTitle = styled.div`
  margin: 30px auto;
  height: 50px;
  width: 50%;
  animation: ${pulse} 1.5s linear infinite;
`;

const StyledLine = styled.div`
  margin: 10px 0;
  height: 30px;
  width: 100%;
  animation: ${pulse} 1.5s linear infinite;
`;

const CopyLoader = () => {
  return (
    <StyledCopyLoader>
      <StyledTitle />
      <StyledLine />
      <StyledLine />
      <StyledLine />
      <StyledLine />
    </StyledCopyLoader>
  );
};

export default CopyLoader;
