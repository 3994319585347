import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

import media from './utilities/breakpoints.js';
import { transitionAll } from './utilities/transitions.js';

export const GlobalStyle = createGlobalStyle`

  body {
    &.locked-scroll {
      overflow: hidden;
    }
  }

  :root {
    font-size: 16px;
    font-family: 'ProximaNova-Light';
    line-height: 1.5rem;
    color: ${(props) => props.theme.sumiInk};
  }

  * {
    font-size: 1.125rem;
  }

  strong {
    font-weight: bold;
    font-family: 'ProximaNova-Semibold';
  }

  h1, h2, h3, h4, h5 {
    font-family: 'ProximaNova-Bold';
    color: ${(props) => props.theme.globalPrimary};
    line-height: 1.2;
    margin-bottom: 0;
  }

  h1 {
    font-size: 2.5rem; /* 40px */
  }

  h2 {
    font-size: 2rem; /* 32px */
  }

  h3 {
    font-size: 1.625rem; /* 26px */
  }

  h4 {
    font-size: 1.375rem /* 22px */;
  }

  h5 {
    font-size: 1.125rem; /* 18px */
  }

  a {
    transition: color 0.2s ease;
    color: ${(props) => props.theme.tealBlue};

    /* Commented out until we can get a colour that works for accessibility from Design */

    /* &:visited {
      color: ${(props) => props.theme.burntOrange};
    }*/
  }


  /* Add global styling for cursor to be a pointer if its any of the below elements, or has a class of pointer */

  a[href], input[type='submit'], input[type='image'], label[for], select, button, .pointer {
    cursor: pointer;
  }

  /* Style reset for buttons */
  button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

  ${media.sm`
    h1 {
      font-size: 2.875rem; /* 46px */
    }

    h2 {
      font-size: 2.5rem; /* 40px */
    }

    h3 {
      font-size: 1.875rem; /* 30px */
    }

    h4 {
      font-size: 1.5rem; /* 24px */
    }

    h5 {
      font-size: 1.25rem; /* 20px */
    }
  `}

  ${media.lg`
    h1 {
      font-size: 3.25rem; /* 52px */
    }

    h2 {
      font-size: 2.875rem; /* 46px */
    }

    h3 {
      font-size: 2rem; /* 32px */
    }

    h4 {
      font-size: 1.625rem; /* 26px */
    }

    h5 {
      font-size: 1.25rem; /* 20px */
    }
  `}
`;

export const StyledLinkContainer = styled.div`
  a {
    color: ${(props) => props.theme.sumiInk};
    font-family: 'ProximaNova-Semibold';
    text-decoration: none;
    border-bottom: solid 1px ${(props) => props.theme.shipCove70};
    line-height: 16px;
    transition: ${transitionAll.transition};

    &:hover {
      border-bottom: solid 4px ${(props) => props.theme.shipCove70};
    }

    &:focus {
      color: ${(props) => props.theme.carbon};
      background: ${(props) => props.theme.givry};
      box-shadow: 0 4px ${(props) => props.theme.carbon};
      outline: 3px solid transparent;
      min-height: 0;
    }
    &:active {
      background: none;
    }
  }
`;
