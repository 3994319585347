import React from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';

import CustomCol from '../../CustomCol.js';

import { pulse, pulseSecondary } from '../../../utilities/animations.js';
import media from '../../../utilities/breakpoints.js';

const StyledWrapper = styled.section`
  background: ${(props) => props.theme.purpleCrystal};
`;

const StyledTitle = styled.div`
  height: 48px;
  width: 75%;
  animation: ${pulseSecondary} 1.5s linear infinite;

  ${media.md`
    height: 55px;
    width: 65%;
  `}

  ${media.lg`
    height: 55px;
    width: 65%;
  `}
`;

const StyledIntroGrid = styled(Grid)`
  margin: -50px auto 0;
`;

const StyledHeroIntro = styled.div`
  height: 150px;
  margin: 0 auto;
  animation: ${pulse} 1.5s linear infinite;

  ${media.md`
    height: 200px;
  `};
`;

const StyledHeroImage = styled.div`
  animation: ${pulseSecondary} 1.5s linear infinite;
  width: 100%;
  height: 500px;

  ${media.sm`
    height: 318.641px;
  `};

  ${media.lg`
    height: 413.547px;
  `};

  ${media.xl`
    height: 501.688px;
  `}
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94.797px;

  ${media.md`
    height: 105.812px;
  `};

  ${media.lg`
    justify-content: flex-start;
    height: 136.828px;
  `}
`;

const StyledCol = styled(Col)`
  padding: 0;
`;

const HubPageHeroLoader = () => {
  return (
    <>
      <StyledWrapper>
        <Grid>
          <Row>
            <StyledCol xs={12}>
              <StyledTitleContainer>
                <StyledTitle />
              </StyledTitleContainer>
              <StyledHeroImage />
            </StyledCol>
          </Row>
        </Grid>
      </StyledWrapper>
      <StyledIntroGrid>
        <Row center="xs">
          <CustomCol xs={12} lg={10}>
            <StyledHeroIntro />
          </CustomCol>
        </Row>
      </StyledIntroGrid>
    </>
  );
};

export default HubPageHeroLoader;
