/**
|--------------------------------------------------
| This is where we define all our our colours to be
| used inside the app. They link up with the colours
| palette that can be found in Zeplin. The only
| difference is they are camel cased.
|
| ⚠️ Colors are grouped as closely as possible.
| If you add a new colour please check there isn't a
| color close enough that can be used. If new color
| has to be added make sure its inserted in he correct
| place ⚠️
|--------------------------------------------------
*/

const colors = {
  black: 'rgba(0, 0, 0, 1)',
  almostBlack: 'rgba(33, 33, 33, 1)',
  carbon: 'rgba(51, 51, 51, 1)',
  sumiInk: 'rgba(87, 87, 87, 1)',
  tundora: 'rgba(70, 70, 70, 1)',
  darkCharcoal: 'rgba(15, 37, 34, 1)',
  black20: 'rgba(0, 0, 0, 0.2)',
  mineShaft: 'rgba(40, 39, 39, 1)',
  white: 'rgba(255, 255, 255, 1)',
  white80: 'rgba(255, 255, 255, 0.8)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white25: 'rgba(255, 255, 255, 0.25)',
  white20: 'rgba(255, 255, 255, 0.20)',
  cascadingWhite: 'rgba(246,246,246, 1)',
  alabaster: 'rgba(250, 250, 250, 1)',
  pearl: 'rgba(248, 249, 250, 1)',
  distantHorizon: 'rgba(242, 248, 251, 1)',
  clearVision: 'rgba(229, 240, 246, 1)',
  purpleCrystal: 'rgba(230, 230, 234, 1)',
  calmTint: 'rgba(235, 229, 235, 1)',
  vapor: 'rgba(234, 234, 234, 1)',
  alto30: 'rgba(210, 210, 210, 0.3)',
  silver: 'rgba(203, 203, 203)',
  deepPurple: 'rgba(57, 3, 59, 1)',
  deepPurple50: 'rgba(57, 3, 59, 0.5)',
  deepPurple80: 'rgba(57, 3, 59, 0.8)',
  curedEggPlant: 'rgba(48, 9, 54, 1)',
  americanPurple: 'rgba(76, 28, 78, 1)',
  kuwanomiPurple: 'rgba(86, 40, 88, 1)',
  seance: 'rgba(102, 36, 130, 1)',
  wineTour: 'rgba(97, 53, 98, 1)',
  blueberry: 'rgba(102, 51, 153, 1)',
  patricianPurple: 'rgba(116, 78, 118, 1)',
  beauty: 'rgba(136, 104, 137, 1)',
  divine: 'rgba(156, 129, 157, 1)',
  martinique: 'rgba(55, 49, 90, 1)',
  martinique80: 'rgba(55, 49, 90, 0.8)',
  comet: 'rgba(93, 85, 132, 1)',
  seacave: 'rgba(0, 89, 136, 1)',
  tealBlue: 'rgba(1, 129, 165, 1)',
  shipCove70: 'rgba(127, 158, 190, 0.7)',
  congressBlue: 'rgba(0, 62, 126, 1)',
  eastBay: 'rgba(57, 65, 111, 1)',
  indigoBunting: 'rgba(1, 112, 171, 1)',
  indigoBunting60: 'rgba(1, 112, 171, 0.6)',
  indigoBunting10: 'rgba(1, 112, 171, 0.1)',
  toryBlue: 'rgba(13, 84, 156, 1)',
  toryBlue80: 'rgba(13, 84, 156, .8)',
  denim: 'rgba(14, 117, 221, 1)',
  gulfBlue: 'rgba(4, 15, 102, 1)',
  gulfBlue80: 'rgba(4, 15, 102, .8)',
  persianBlue: 'rgba(23, 42, 178, 1)',
  blueCuddle: 'rgba(128, 183, 213, 1)',
  spottedSnakeEel: 'rgba(178, 212, 229, 1)',
  hintOfTidalFoam: 'rgba(204, 226, 238, 1)',
  linkWater: 'rgba(231, 240, 249, 1)',
  zumthor: 'rgba(236, 245, 255, 1)',
  deepCerulean: 'rgba(0, 128, 165, 1)',
  navajoTurquoise: 'rgba(0, 128, 119, 1)',
  navajoTurquoise60: 'rgba(0, 128, 119,0.6)',
  tatzelwurmGreen: 'rgba(58, 106, 0, 1)',
  pistachioFlour: 'rgba(3, 133, 0, 1)',
  pistachioFlour60: 'rgba(73, 133, 0, 0.6)',
  tropicalRainForest: 'rgba(0, 103, 78, 1)',
  pistachioFlour10: 'rgba(73, 133, 0, 0.1)',
  greenSky: 'rgba(131, 155, 102, 1)',
  olivine: 'rgba(164, 194, 128, 1)',
  opalineGreen: 'rgba(164, 194, 128, 1)',
  fuego: 'rgba(175, 226, 10, 1)',
  whiteVienna: 'rgba(200, 218, 178, 1)',
  mistyHillside: 'rgba(219, 231, 204, 1)',
  kidnapper: 'rgba(211, 225, 195, 1)',
  alaskanMist: 'rgba(236, 242, 229, 1)',
  emeraldStone: 'rgba(0, 102, 95, 1)',
  costalFringe: 'rgba(127, 191, 187, 1)',
  dantePeak: 'rgba(178, 216, 214, 1)',
  ziggurat: 'rgba(195, 225, 223, 1)',
  lightSupernova: 'rgba(204, 230, 228, 1)',
  calmWaters: 'rgba(229, 249, 247, 1)',
  aquaTint: 'rgba(229, 242, 241, 1)',
  aquaTint60: 'rgba(229, 242, 241, 0.6)',
  amazonite: 'rgba(4, 191, 178, 1)',
  amazonite60: 'rgba(4, 191, 178, 0.6)',
  amazonite20: 'rgba(4, 191, 178, 0.2)',
  peace: 'rgba(163, 180, 190, 1)',
  hollyGlen: 'rgba(160, 180, 179, 1)',
  endlessSlumber: 'rgba(175, 171, 179, 1)',
  gray: 'rgba(145, 145, 145, 1)',
  temptress: 'rgba(74, 0, 0, 1)',
  cardinal: 'rgba(205, 37, 35, 1)',
  deepRed: 'rgba(179, 1, 0, 1)',
  jesterRed: 'rgba(147, 17, 57, 1)',
  carmoisine: 'rgba(184, 22, 72, 1)',
  carmoisine60: 'rgba(184, 22, 72, 0.6)',
  primrose: 'rgba(219, 138, 163, 1)',
  lavenderRose: 'rgba(252, 146, 222, 1)',
  magentaTwilight: 'rgba(186, 148, 160, 1)',
  amourFrais: 'rgba(247, 231, 236, 1)',
  givry: 'rgb(247, 235, 180)',
  hydrangeaPink: 'rgba(233, 185, 200, 1)',
  vanillaIce: 'rgba(234, 193, 206, 1)',
  ballerina: 'rgba(241, 208, 218, 1)',
  duskyLilac: 'rgba(215, 205, 216, 1)',
  fuelYellow: 'rgba(239, 162, 53, 1)',
  honeyGraham: 'rgba(184, 132, 102, 1)',
  supernova: 'rgba(241, 203, 0, 1)',
  muddyBrown: 'rgba(134, 112, 4, 1)',
  buddhaGold: 'rgba(194, 162, 4, 1)',
  carmim: 'rgba(166, 61, 1, 1)',
  burntOrange: 'rgba(202, 75, 2, 1)',
  burntOrange60: 'rgba(202, 75, 2, 0.6)',
  burntOrange10: 'rgba(202, 75, 2, 0.1)',
  orangeClay: 'rgba(231, 166, 128, 1)',
  hintOfFirstLove: 'rgba(250, 237, 229, 1)',
  lightMaidensBlush: 'rgba(246, 219, 204, 1)',
  cashewNut: 'rgba(240, 201, 179, 1)',
  mandysPink: 'rgba(242, 207, 186, 1)',
  youtubeRed: 'rgba(255, 0, 0, 1)',
  facebookBlue: 'rgba(24, 119, 242, 1)',
  twitterBlue: 'rgba(29, 161, 242, 1)',
  linkedInBlue: 'rgba(0, 102, 153, 1)',
  finn: 'rgba(102, 36, 98, 1)',
  eastSide: 'rgba(183,128,207, 1)',
  finn50: 'rgba(102, 36, 98, 0.5)',
  webOrange: 'rgba(255, 165, 2, 1)',
  goldenTainoi: 'rgba(255, 201, 102, 1)',
  spectra: 'rgba(46, 84, 94, 1)',
  woodsmoke: 'rgba(7, 9, 8, 1)',
  grey: 'rgba(117,117,117,1)',
};

export default colors;
