import gql from 'graphql-tag';

import {
  AccordionGroup,
  ParagraphTextBlock,
  ParagraphCtaBanner,
  ParagraphCardCarousel,
  ParagraphLogoStrip,
  SocialMedia,
  SocialMediaTag,
  VideoGallery,
  ParagraphFullWidthBlock,
  ParagraphEventBlock,
} from '../fragments/fragments.js';

export const CAMPAIGN_LANDING_QUERY = gql`
  query Campaign($path: String!) {
    route(path: $path) {
      ... on EntityCanonicalUrl {
        footerNode: entity {
          ... on NodeCampaign {
            footerTitle: fieldFooterTitle
            footerEmail: fieldEmail
            footerPhone: fieldPhone
            footerLocation: fieldLocationVenue
            footerMapLink: fieldGoogleMapsUrl {
              url {
                path
              }
            }
            openNewWindow: fieldBoolean
          }
        }
        node: entity {
          ... on NodeCampaign {
            nid: nid
            title: title
            theme: fieldTheme
            logo: fieldImage {
              entity {
                ... on MediaCampaignLogo {
                  fieldMediaImage1 {
                    campaignlogo: derivative(style: CAMPAIGNLOGO) {
                      url
                      height
                      width
                    }
                    alt
                  }
                }
              }
            }
            navItems: fieldComponentFour {
              entity {
                ... on ParagraphNavigationItem {
                  uuid
                  paragraphType: type {
                    targetId
                  }
                  fieldTitle
                  fieldCaption
                }
              }
            }
            tagLink: fieldLink {
              link: url {
                path
              }
            }
            tagText: fieldKey
            bannerStrap: fieldTitle
            bannerIntro: fieldIntroText {
              value
              processed
              format
            }
            buttons: fieldAnnouncementCards {
              button: entity {
                id: entityUuid
                type: entityBundle
                ... on ParagraphLink {
                  uuid
                  paragraphType: type {
                    targetId
                  }
                  openNewWindow: fieldBoolean
                  fieldLink {
                    title
                    uri
                    url {
                      path
                    }
                  }
                }
              }
            }
            bannerMedia: fieldBannerMedia {
              entity {
                ... on ParagraphVideoBlock {
                  uuid
                  paragraphType: type {
                    targetId
                  }
                  fieldCaption
                  fieldImage {
                    entity {
                      ... on MediaImage {
                        fieldMediaImage {
                          url
                          width
                          height
                          alt
                          derivative(style: CAMPAIGNBANNER) {
                            url
                          }
                        }
                      }
                    }
                  }
                  fieldVideo {
                    entity {
                      ... on MediaRemoteVideo {
                        remoteUrl: fieldMediaOembedVideo
                        thumbnail {
                          title
                          url
                        }
                        title: entityLabel
                      }
                    }
                  }
                }
                ... on ParagraphImageBlock {
                  uuid
                  paragraphType: type {
                    targetId
                  }
                  fieldCaption
                  fieldImage {
                    entity {
                      ... on MediaImage {
                        fieldMediaImage {
                          alt
                          derivative(style: CAMPAIGNBANNER) {
                            url
                            height
                            width
                          }
                          placeholder: derivative(style: CAMPAIGNBANNER) {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            textBlock: fieldDate
            parentGradient: fieldBooleanOne
            childGradient: fieldBooleanTwo
            paragraphs: fieldComponents {
              paragraph: entity {
                ...ParagraphCtaBanner
                ...ParagraphTextBlock
                ...ParagraphAccordionGroup
                ...ParagraphCardCarousel
                ...ParagraphLogoStrip
                ...ParagraphSocialMedia
                ...ParagraphSocialTag
                ...ParagraphVideoGallery
                ...ParagraphFullWidthBlock
                ...ParagraphEventBlock
              }
            }
          }
        }
      }
    }
  }
  ${AccordionGroup}
  ${ParagraphTextBlock}
  ${ParagraphCtaBanner}
  ${ParagraphCardCarousel}
  ${ParagraphLogoStrip}
  ${SocialMedia}
  ${SocialMediaTag}
  ${VideoGallery}
  ${ParagraphFullWidthBlock}
  ${ParagraphEventBlock}
`;

export default CAMPAIGN_LANDING_QUERY;
