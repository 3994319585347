import React from 'react';

import { Query } from 'react-apollo';

import styled from 'styled-components';

import Title from '../Common-components/Title/Title.js';

import FOOTER_MENU_QUERY from '../../../queries/campaign/FooterQuery.js';
import media from '../../../utilities/breakpoints.js';

const StyledFooterHeading = styled(Title)`
  font-size: 27px;
  padding: 32px 0 10px;
  margin: 0;
`;

const StyledFooterUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  columns: auto;
  ${media.md`
    columns: ${(props) => (props.linksLength >= 4 ? '3 auto' : 'auto')};
  `}
`;

const StyledFooterItem = styled.li`
  list-style-type: none;
  padding: 5px 0;
  margin: 0;
`;

const StyledFooterLink = styled.a`
  color: ${(props) => props.theme.white};
  font-size: 18px;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
`;

// Default theme values
StyledFooterLink.defaultProps = {
  theme: {
    white: 'rgba(255,255,255, 1)',
  },
};

const UsefulLinks = () => {
  return (
    <Query query={FOOTER_MENU_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return 'Loading...';
        if (error) return `Error! ${error.message}`;
        const footerLinks = data.menu.links;
        return (
          <>
            <StyledFooterHeading>Useful links</StyledFooterHeading>
            <StyledFooterUl linksLength={footerLinks.length}>
              {footerLinks &&
                footerLinks.map((link, i) => {
                  return (
                    <StyledFooterItem key={`footerLinks-${i}`}>
                      <StyledFooterLink
                        href={link.url.path}
                        title={link.description}
                      >
                        {link.label}
                      </StyledFooterLink>
                    </StyledFooterItem>
                  );
                })}
            </StyledFooterUl>
          </>
        );
      }}
    </Query>
  );
};

export default UsefulLinks;
