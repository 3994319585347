import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';
import { ThemeProvider } from 'styled-components';
import loadable from '@loadable/component';
import styled from 'styled-components';
import { SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import DocumentTitle from 'react-document-title';

import { main } from './utilities/config/themes.js';
import HUBNAME_MAPPING from './utilities/hubNameMapping.js';
import media from './utilities/breakpoints.js';
import getCookie from './utilities/getCookie.js';

import Footer from './components/Footer.js';
import CookieBanner from './components/CookieBanner.js';
import Campaign from './components/Campaign/Campaign.js';

import { GlobalStyle } from './GlobalStyle.js';
import { CampaignGlobalStyles } from './CampaignGlobalStyles.js';

const MySurrey = loadable(() => import('./hubs/MySurrey.js'));
const Accommodation = loadable(() => import('./hubs/Accommodation.js'));
const CampusLife = loadable(() => import('./hubs/CampusLife.js'));
const EmployabilityCareers = loadable(() =>
  import('./hubs/EmployabilityCareers.js')
);
const ExamsAssessments = loadable(() => import('./hubs/ExamsAssessments.js'));
const GetInvolved = loadable(() => import('./hubs/GetInvolved.js'));
const Graduation = loadable(() => import('./hubs/Graduation.js'));
const IT = loadable(() => import('./hubs/IT.js'));
const Library = loadable(() => import('./hubs/Library.js'));
const Money = loadable(() => import('./hubs/Money.js'));
const Policies = loadable(() => import('./hubs/Policies.js'));
const Study = loadable(() => import('./hubs/Study.js'));
const Research = loadable(() => import('./hubs/Research.js'));
const Transport = loadable(() => import('./hubs/Transport.js'));
const Wellbeing = loadable(() => import('./hubs/Wellbeing.js'));
const VisasImmigration = loadable(() => import('./hubs/VisasImmigration.js'));
const NotFound = loadable(() => import('./pages/NotFound.js'));

const StyledApp = styled.div`
  img {
    max-width: 100%;
  }
`;

const StyledFullHeight = styled.div`
  min-height: 100vh;
`;

const StyledSkipNavLink = styled(SkipNavLink)`
  &[data-reach-skip-link] {
    top: 80px;
    ${media.md`
      top: 140px;
    `}
  }
  &[data-reach-skip-link]:focus {
    background-color: ${(props) => props.theme.globalPrimary};
    color: ${(props) => props.theme.white};
  }
`;

const SUBDOMAIN_MAPPING_COMPONENT = {
  accommodation: Accommodation,
  campus: CampusLife,
  careers: EmployabilityCareers,
  exams: ExamsAssessments,
  getinvolved: GetInvolved,
  graduation: Graduation,
  it: IT,
  library: Library,
  money: Money,
  my: MySurrey,
  policies: Policies,
  research: Research,
  study: Study,
  transport: Transport,
  visas: VisasImmigration,
  wellbeing: Wellbeing,
};

const App = () => {
  const [subdomain, setsubDomain] = useState('');

  useEffect(() => {
    const url = window.location.host;
    const firstPart = url.split('.')[0];

    if (firstPart === 'researchers') {
      // Only live url handled as we dont care about this locally
      window.location.href = 'https://research.surrey.ac.uk/';
    } else if (firstPart === 'graduation') {
      window.location.href = 'https://surrey.ac.uk/graduation';
    }

    setsubDomain(firstPart);
  }, []);

  if (!subdomain) return null;

  let HubComponent = SUBDOMAIN_MAPPING_COMPONENT[subdomain] || NotFound;

  const hubName = HUBNAME_MAPPING[subdomain];

  const PathName = window.location.pathname.split('/')[1];

  const campaignUrl = window.location.pathname;

  // Get cookie to check whether or not we should show the cookie banner
  const hasUserAcceptedCookies = getCookie('mySurreyUserAcceptCookies');

  if (PathName === 'campaign') {
    return (
      <>
        <CampaignGlobalStyles />
        <Router>
          <Campaign path="*" campaignUrl={campaignUrl} hubName={hubName} />
        </Router>
      </>
    );
  } else {
    return (
      <ThemeProvider theme={main}>
        <StyledSkipNavLink>Skip to content</StyledSkipNavLink>
        <>
          <GlobalStyle />
          <StyledApp>
            <StyledFullHeight>
              <DocumentTitle title="University Of Surrey | MySurrey">
                <Router>
                  <HubComponent path="*" />
                </Router>
              </DocumentTitle>
            </StyledFullHeight>
            {hasUserAcceptedCookies !== 'true' && <CookieBanner />}
            <Footer hubName={hubName} />
          </StyledApp>
        </>
      </ThemeProvider>
    );
  }
};

export default App;
