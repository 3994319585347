import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Header from '../Campaign/Header/Header.js';

const QueryError = () => {
  return (
    <Header>
      <Grid>
        <Row>
          <Col>Oops Something went wrong</Col>
        </Row>
      </Grid>
    </Header>
  );
};

export default QueryError;
