import colors from './colors.js';

/**
|--------------------------------------------------
| We import the colours palette 👆🏻 then we use the
| colours 👇🏻 to make up the various hubs and
| sections.
|--------------------------------------------------
*/

/**
|--------------------------------------------------
| Below is an example object of how we structure a
| theme with some comments to try and help. Please
| use this as a guide when setting up a new theme.
|--------------------------------------------------
*/

/**
|--------------------------------------------------
|
| 💥 EXAMPLE THEME BELOW!! 💥
|
| 🛑 DO NOT USE! ONLY FOR REFERENCE! 🛑
|   👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻👇🏻
|
|--------------------------------------------------
*/

export const exampleTheme = {
  ...colors,
  // Primary controls: 👇🏻
  // - Navigation
  // - CTA Button text
  // - Video caption
  // - CTA Banner background
  // - Accordion
  // - Video gallery (inactive videos)
  primary: colors.gulfBlue,

  // Primary80 controls: 👇🏻
  // - Video gallery (active video)
  // - Video gallery play button
  primary80: colors.gulfBlue80,

  // primaryHover controls: 👇🏻
  // - Tag Text background (Not hover I know this is slightly confusing :/ )
  // - Navigation Hover state
  // - CTA Hover when not on dark background
  primaryHover: colors.persianBlue,

  // secondary controls: 👇🏻
  // - The background colour of the accordion body text
  secondary: colors.linkWater,

  // attention controls: 👇🏻
  // - CTA Button background
  // - Banner Title
  attention: colors.lavenderRose,

  // gradientOne & gradientTwo controls: 👇🏻
  // - Banner gradients
  // - These gradients will only show if the checkbox's (Add theme gradient for the middle block banner, Add theme gradient for the full width banner) are checked. These are generally dark colours which work in contrast against attention and bannerText which control the text colour for the banner.
  gradientOne: colors.gulfBlue,
  gradientTwo: colors.gulfBlue,

  // navBorder controls: 👇🏻
  // - Border between li's
  navBorder: colors.white,

  // bannerText controls: 👇
  // - Banner h2 and strapline
  bannerText: colors.white,
};

// Themes to be removed and replaced with mysurrey once live content is changed over
export const nss = {
  ...colors,
  primary: colors.deepPurple,
  primary80: colors.deepPurple80,
  primaryHover: colors.finn,
  secondary: colors.calmTint,
  attention: colors.webOrange,
  gradientOne: colors.spectra,
  gradientTwo: colors.woodsmoke,
  backgroundColor: colors.black,
  // This value is so we can decide if the social icons needs to be or a dark or light background
  darkBackground: true,
  navBorder: colors.white,
  bannerTitle: colors.white,
  bannerText: colors.white,
  buttonText: colors.deepPurple,
};

export const mysurrey = {
  ...colors,
  primary: colors.deepPurple,
  primary80: colors.deepPurple80,
  primaryHover: colors.finn,
  secondary: colors.calmTint,
  attention: colors.webOrange,
  gradientOne: colors.spectra,
  gradientTwo: colors.woodsmoke,
  navBorder: colors.white,
  bannerTitle: colors.white,
  bannerText: colors.white,
  buttonText: colors.deepPurple,
  darkBackground: true,
};

export const mauve = {
  ...colors,
  primary: colors.martinique,
  primary80: colors.martinique80,
  primaryHover: colors.comet,
  secondary: colors.calmTint,
  attention: colors.fuego,
  gradientOne: colors.martinique,
  gradientTwo: colors.martinique,
  navBorder: colors.white,
  bannerText: colors.white,
  darkBackground: true,
};

export const lightBlue = {
  ...colors,
  primary: colors.toryBlue,
  primary80: colors.toryBlue80,
  primaryHover: colors.denim,
  secondary: colors.linkWater,
  attention: colors.goldenTainoi,
  gradientOne: colors.toryBlue,
  gradientTwo: colors.toryBlue,
  navBorder: colors.white,
  bannerText: colors.white,
  darkBackground: true,
};

export const darkBlue = {
  ...colors,
  primary: colors.gulfBlue,
  primary80: colors.gulfBlue80,
  primaryHover: colors.persianBlue,
  secondary: colors.linkWater,
  attention: colors.lavenderRose,
  gradientOne: colors.gulfBlue,
  gradientTwo: colors.gulfBlue,
  navBorder: colors.white,
  bannerText: colors.white,
  darkBackground: true,
};
