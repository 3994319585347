/**
|--------------------------------------------------
| Where we list all of our transitions to be used
| within the app. Add transitions here and then import
| using named imports to the component that you wish
| to add the transition to.
| Example usage: transition: ${scaleUp.transition};
|--------------------------------------------------
*/

export const transitionAll = {
  transition: 'all 200ms ease-in-out',
};

export const transitionBackground = {
  transition: 'background 200ms ease-in',
};

export const transitionColor = {
  transition: 'color 100ms ease-in',
};

export const scaleUp = {
  transition: 'transform 200ms ease-in-out',
};

export const customEase = {
  easeIn: 'cubic-bezier(.2,1,.3,1)',
};
