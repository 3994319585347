import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledChevronIcon = styled.svg`
  display: inline-block;
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
  transform-origin: center;
  flex-shrink: 0;

  ${(props) =>
    props.type === 'light' &&
    css`
      color: ${(props) => props.theme.white};
    `}

  ${(props) =>
    props.type === 'dark' &&
    css`
      color: ${(props) => props.theme.black};
    `}

  ${(props) =>
    props.orientation === 'left' &&
    css`
      transform: rotate(-90deg);
    `}

  ${(props) =>
    props.orientation === 'right' &&
    css`
      transform: rotate(90deg);
    `}

    ${(props) =>
    props.orientation === 'up' &&
    css`
      transform: rotate(0deg);
    `}

    ${(props) =>
    props.orientation === 'down' &&
    css`
      transform: rotate(-180deg);
    `}

  ${(props) =>
    props.active &&
    css`
      transform: rotate(0);
    `}
`;

const ChevronIcon = ({
  active,
  orientation,
  type,
  className,
  width,
  height,
  fatChevron,
  fill,
}) => {
  return (
    <>
      <StyledChevronIcon
        xmlns="http://www.w3.org/2000/svg"
        viewBox={fatChevron ? '0 0 48 48' : '0 0 300 300'}
        active={active ? true : false}
        className={className}
        orientation={orientation}
        type={type}
        width={width}
        height={height}
      >
        {fatChevron && (
          <>
            <polygon
              fill={fill}
              points="10 5.9 22.9 5.9 38 24.2 23.8 42 10 42 24.3 24 10 5.9"
              transform="rotate(-90 25 25)"
            />
          </>
        )}
        {!fatChevron && (
          <path d="M282.082 195.285L149.028 62.24c-1.901-1.903-4.088-2.856-6.562-2.856s-4.665.953-6.567 2.856L2.856 195.285C.95 197.191 0 199.378 0 201.853c0 2.474.953 4.664 2.856 6.566l14.272 14.271c1.903 1.903 4.093 2.854 6.567 2.854s4.664-.951 6.567-2.854l112.204-112.202 112.208 112.209c1.902 1.903 4.093 2.848 6.563 2.848 2.478 0 4.668-.951 6.57-2.848l14.274-14.277c1.902-1.902 2.847-4.093 2.847-6.566.001-2.476-.944-4.666-2.846-6.569z" />
        )}
      </StyledChevronIcon>
    </>
  );
};

ChevronIcon.defaultProps = {
  active: false,
  orientation: 'down',
  type: 'light',
  fatChevron: false,
};

ChevronIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  type: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fatChevron: PropTypes.bool.isRequired,
  fill: PropTypes.string,
};

export default ChevronIcon;
