import colors from './colors.js';

/**
|--------------------------------------------------
| We import the colours palette 👆🏻 then we use the
| colours 👇🏻 to make up the various hubs and
| sections.
|--------------------------------------------------
*/

export const main = {
  ...colors,
  globalPrimary: colors.deepPurple, // This variable is set to overrule all primary colours
  primary: colors.deepPurple,
  secondary: colors.calmTint,
  hubOpacityDarken: colors.divine,
  ctaHover: colors.americanPurple,
};

export const accommodation = {
  primary: colors.burntOrange,
  secondary: colors.hintOfFirstLove,
  hubOpacity: colors.burntOrange60,
  hubOpacityDarken: colors.mandysPink,
  ctaHover: colors.carmim,
};

export const campusLife = {
  primary: colors.carmoisine,
  secondary: colors.amourFrais,
  hubOpacity: colors.carmoisine60,
  hubOpacityDarken: colors.vanillaIce,
  ctaHover: colors.jesterRed,
};

export const employabilityCareers = {
  primary: colors.indigoBunting,
  secondary: colors.clearVision,
  hubOpacity: colors.indigoBunting60,
  hubOpacityDarken: colors.spottedSnakeEel,
  ctaHover: colors.toryBlue,
};

export const examsAssessments = {
  primary: colors.navajoTurquoise,
  secondary: colors.aquaTint,
  hubOpacity: colors.navajoTurquoise60,
  hubOpacityDarken: colors.ziggurat,
  ctaHover: colors.deepCerulean,
};

export const getInvolved = {
  primary: colors.carmoisine,
  secondary: colors.amourFrais,
  hubOpacity: colors.carmoisine60,
  hubOpacityDarken: colors.vanillaIce,
  ctaHover: colors.jesterRed,
};

export const graduation = {
  primary: colors.carmoisine,
  secondary: colors.amourFrais,
  hubOpacity: colors.carmoisine60,
  hubOpacityDarken: colors.vanillaIce,
  ctaHover: colors.jesterRed,
};

export const visasImmigration = {
  primary: colors.indigoBunting,
  secondary: colors.clearVision,
  hubOpacity: colors.indigoBunting60,
  hubOpacityDarken: colors.spottedSnakeEel,
  ctaHover: colors.toryBlue,
};

export const it = {
  primary: colors.indigoBunting,
  secondary: colors.clearVision,
  hubOpacity: colors.indigoBunting60,
  hubOpacityDarken: colors.spottedSnakeEel,
  ctaHover: colors.toryBlue,
};

export const library = {
  primary: colors.pistachioFlour,
  secondary: colors.alaskanMist,
  hubOpacity: colors.pistachioFlour60,
  hubOpacityDarken: colors.kidnapper,
  ctaHover: colors.tropicalRainForest,
};

export const money = {
  primary: colors.burntOrange,
  secondary: colors.hintOfFirstLove,
  hubOpacity: colors.burntOrange60,
  hubOpacityDarken: colors.mandysPink,
  ctaHover: colors.carmim,
};

export const policies = {
  primary: colors.pistachioFlour,
  secondary: colors.alaskanMist,
  hubOpacity: colors.pistachioFlour60,
  hubOpacityDarken: colors.kidnapper,
  ctaHover: colors.tropicalRainForest,
};

export const research = {
  primary: colors.pistachioFlour,
  secondary: colors.alaskanMist,
  hubOpacity: colors.pistachioFlour60,
  hubOpacityDarken: colors.kidnapper,
  ctaHover: colors.tropicalRainForest,
};

export const study = {
  primary: colors.navajoTurquoise,
  secondary: colors.aquaTint,
  hubOpacity: colors.navajoTurquoise60,
  hubOpacityDarken: colors.ziggurat,
  ctaHover: colors.deepCerulean,
};

export const transport = {
  primary: colors.burntOrange,
  secondary: colors.hintOfFirstLove,
  hubOpacity: colors.burntOrange60,
  hubOpacityDarken: colors.mandysPink,
  ctaHover: colors.carmim,
};

export const wellbeing = {
  primary: colors.navajoTurquoise,
  secondary: colors.aquaTint,
  hubOpacity: colors.navajoTurquoise60,
  hubOpacityDarken: colors.ziggurat,
  ctaHover: colors.deepCerulean,
};
