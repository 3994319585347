import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemeProvider, css } from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import DocumentTitle from 'react-document-title';
import { Query } from 'react-apollo';
import { useLocation } from '@reach/router';

// Import Components
import Footer from './Footer/Footer.js';
import Header from './Header/Header.js';
import Paragraph from './Paragraphs/Paragraph.js';
import Navigation from './Navigation/Navigation.js';
import Banner from './Banner/Banner.js';

// Import loaders and error components
import HubPageLoader from '../Loaders/HubPageLoader.js';
import HubPageHeroLoader from '../Loaders/Components/HubPageHeroLoader.js';
import CampaignError from '../Error/CampaignError.js';
import CampaignNotFound from '../Error/CampaignNotFound.js';
import scrollToId from '../../utilities/scrollToId.js';

// Import campaign themes
import {
  nss,
  mysurrey,
  mauve,
  lightBlue,
  darkBlue,
} from '../../utilities/config/campaignThemes.js';
// Import utilities
import { trackGTM } from '../../utilities/trackGTM.js';

// Import queries
import CAMPAIGN_QUERY from '../../queries/campaign/CampaignQuery.js';

const CAMPAIGN_MAPPING_COMPONENT = {
  nss: nss,
  mysurrey: mysurrey,
  mauve: mauve,
  lightBlue: lightBlue,
  darkBlue: darkBlue,
};

const StyledFullHeight = styled.div`
  min-height: 100vh;
`;

const StyledNoPaddingCol = styled(Col)`
  padding: 0;
`;

const StyledParentGradient = styled.section`
  ${(props) =>
    (props.parentGradient &&
      css`
        background-image: linear-gradient(
          to left,
          ${(props) => props.theme.gradientTwo} 40%,
          ${(props) => props.theme.gradientOne}
        );
      `) ||
    `background: white;`}
`;

const Campaign = ({ campaignUrl, hubName }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '');
      scrollToId(id);
    }
  }, [location.hash]);

  return (
    <Query query={CAMPAIGN_QUERY} variables={{ path: campaignUrl }}>
      {({ loading, error, data }) => {
        if (loading)
          return (
            <>
              <HubPageHeroLoader />
              <HubPageLoader />
            </>
          );

        if (error) return <CampaignError />;

        if (!data.route) return <CampaignNotFound />;

        let paragraphs;

        let footerContactData;

        // Set node so we donst have to drill down every time
        const node = data.route.node;

        paragraphs = data.route.node.paragraphs || [];

        footerContactData = data.route.footerNode || [];

        let campaignTheme = CAMPAIGN_MAPPING_COMPONENT[node.theme] || mysurrey;

        trackGTM(node.title, window.location.href);

        return (
          <>
            <Header logo={node.logo} />
            <ThemeProvider theme={campaignTheme} role="main">
              <StyledFullHeight>
                <DocumentTitle title={node.title} />

                <main role="main">
                  <StyledParentGradient parentGradient={node.parentGradient}>
                    <Grid>
                      <Row>
                        <StyledNoPaddingCol xs={12}>
                          <Banner
                            campaignTheme={campaignTheme}
                            bannerTagLink={node.tagLink}
                            bannerTagText={node.tagText}
                            bannerTitle={node.title}
                            bannerStrap={node.bannerStrap}
                            bannerIntro={node.bannerIntro}
                            bannerButton={node.buttons}
                            bannerTextBlock={node.textBlock}
                            childGradient={node.childGradient}
                            paragraph={
                              node.bannerMedia !== null
                                ? node.bannerMedia.entity
                                : node.bannerMedia
                            }
                          />
                        </StyledNoPaddingCol>
                      </Row>
                    </Grid>
                  </StyledParentGradient>
                  <Navigation
                    role="navigation"
                    navItems={data.route.node.navItems}
                  ></Navigation>
                  {paragraphs &&
                    paragraphs.map((item, i) => {
                      return (
                        <Paragraph
                          type={item.paragraph.paragraphType.targetId}
                          values={item.paragraph}
                          key={item.paragraph.uuid}
                          context="CampaignTheme"
                          campaignTheme={campaignTheme}
                          hubName={hubName}
                          nodeId={node.nid.toString()}
                        />
                      );
                    })}
                </main>
              </StyledFullHeight>
            </ThemeProvider>
            <Footer footerContactData={footerContactData} />
          </>
        );
      }}
    </Query>
  );
};

Campaign.propTypes = {
  campaignUrl: PropTypes.string.isRequired,
  hubName: PropTypes.string,
};

export default Campaign;
