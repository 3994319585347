import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { scaleUp } from '../utilities/transitions.js';
import { fancyAnimationScale, fadein } from '../utilities/animations.js';

const StyledButtonContainer = styled.span`
  display: flex;
  border-radius: 3px;
  margin: ${(props) => (props.margin ? props.margin : '20px 0')};
  justify-content: ${(props) => (props.flexCenter ? 'center' : 'normal')};
  height: ${(props) => (props.fillHeight ? '100%' : 'auto')};

  > * {
    padding: ${(props) => (props.padding ? props.padding : '13px 30px')};
    font-size: 18px;
    font-weight: 600;
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};
    text-decoration: none;
    overflow: hidden;
    position: relative;
    display: inline-block;
    border-radius: 3px;
    text-align: center;
    border: ${(props) => (props.border ? '3px solid transparent' : 'none')};
    transform: scale(1);
    transition: ${scaleUp.transition};
    box-shadow: ${(props) =>
      props.noDropShadow ? `none` : `0 2px 4px 0 rgba(0, 0, 0, 0.25)`};

    &::after {
      content: '';
      height: 50px;
      width: 50px;
      position: absolute;
      right: -50px;
      bottom: -50px;
      border-radius: 50%;
      background-color: ${(props) =>
        props.borderColour ? props.borderColour : props.theme.white50};
      animation: ${fadein} 600ms ease-in;
    }

    ${(props) =>
      props.primary &&
      css`
        background-color: ${(props) => props.theme.primary};
      `}

    ${(props) =>
      props.secondary &&
      css`
        color: ${(props) => props.theme.sumiInk};
        background-color: ${(props) => props.theme.secondary};
      `}

    ${(props) =>
      props.buttonColour &&
      css`
        background-color: ${(props) => props.buttonColour};
      `}


    &:hover {
      color: ${(props) => props.theme.white};
      &::after {
        transform: scale(31);
        animation: ${fancyAnimationScale} 600ms ease-in-out;
        opacity: 0.2;
      }
    }

    &:focus {
      outline: 3px solid ${(props) => props.theme.givry};
    }

    ${(props) =>
      props.buttonType === 'fancy' &&
      css`
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        border-bottom: 5px solid
          ${(props) =>
            props.borderColour ? props.borderColour : props.theme.white50};
        padding-bottom: 11px;

        &:focus {
          border-bottom: 5px solid
            ${(props) =>
              props.borderColour ? props.borderColour : props.theme.white50};
        }

        &::after {
          content: '';
          right: -25px;
          bottom: -25px;
          border-radius: 50%;
          background-color: ${(props) =>
            props.borderColour ? props.borderColour : props.theme.white50};
        }
      `}

    ${(props) =>
      props.buttonType === 'outline' &&
      css`
        background-color: transparent;
        color: ${(props) =>
          props.buttonColour ? props.buttonColour : props.theme.primary};
        border: 3px solid
          ${(props) =>
            props.buttonColour ? props.buttonColour : props.theme.primary};
        &:hover {
          text-decoration: underline;
          color: ${(props) =>
            props.buttonColour ? props.buttonColour : props.theme.primary};
        }
        &::after {
          content: none;
        }
      `}
  }
`;

const Button = ({
  as: Element,
  className,
  children,
  openNewWindow,
  buttonType,
  buttonColour,
  primary,
  secondary,
  invert,
  padding,
  margin,
  noDropShadow,
  border,
  fillHeight,
  flexCenter,
  ...rest
}) => {
  return (
    <StyledButtonContainer
      primary={primary}
      secondary={secondary}
      buttonType={buttonType}
      buttonColour={buttonColour}
      invert={invert}
      padding={padding}
      margin={margin}
      noDropShadow={noDropShadow}
      border={border}
      fillHeight={fillHeight}
      flexCenter={flexCenter}
    >
      <Element
        className={className}
        {...rest}
        target={openNewWindow ? '_blank' : '_self'}
      >
        {children}
      </Element>
    </StyledButtonContainer>
  );
};

Button.propTypes = {
  Element: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  children: PropTypes.node.isRequired,
  buttonColour: PropTypes.string,
  borderColour: PropTypes.string,
  buttonType: PropTypes.oneOf(['fancy', 'outline']),
  fontSize: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  openNewWindow: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.string,
  invert: PropTypes.bool,
  padding: PropTypes.string,
  margin: PropTypes.string,
  noDropShadow: PropTypes.bool,
  border: PropTypes.bool,
  fillHeight: PropTypes.bool,
  flexCenter: PropTypes.bool,
};

export default Button;
