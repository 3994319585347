import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledHeader = styled.span`
  color: ${(props) =>
    props.themeColor === 'light'
      ? props.theme.white
      : props.theme.globalPrimary};
  display: inline-block;
  margin-bottom: 0;
`;

const Header = ({ as: Element, children, className, themeColor }) => {
  return (
    <StyledHeader className={className} as={Element} themeColor={themeColor}>
      {children}
    </StyledHeader>
  );
};

export default Header;

Header.defaultProps = {
  as: 'h1',
  theme: 'dark',
};

Header.propTypes = {
  as: PropTypes.node,
  children: PropTypes.string,
  className: PropTypes.string,
  themeColor: PropTypes.oneOf(['dark', 'light']),
};
