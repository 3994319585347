const HUBNAME_MAPPING = {
  accommodation: 'Accommodation',
  campus: 'Campus Life',
  careers: 'Employability Careers',
  exams: 'Exams Assessments',
  getinvolved: 'Get involved',
  graduation: 'Graduation',
  it: 'IT',
  library: 'Library',
  money: 'Money',
  my: 'MySurrey',
  policies: 'Policies',
  research: 'Research',
  study: 'Study',
  transport: 'Transport',
  visas: 'Visas Immigration',
  wellbeing: 'Wellbeing',
};

export default HUBNAME_MAPPING;
