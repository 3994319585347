const scrollToId = (id) => {
  if (!id) return;

  // Select the node that will be observed for mutations
  const targetNode = document.querySelector('body');

  // Options for the observer (which mutations to observe)
  const config = { childList: true, subtree: true };

  // Callback function to execute when mutations are observed
  const callback = () => {
    const anchor = document.getElementById(id);

    if (anchor !== null) {
      // Scroll to anchor
      anchor.scrollIntoView();
      // Set timeout for Chrome to work
      setTimeout(() => {
        // Disconnect MutationObserver as we are done
        watcher.disconnect();
      }, 1000);
    }
  };

  // Create an observer instance linked to the callback function
  const watcher = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  watcher.observe(targetNode, config);
};

export default scrollToId;
