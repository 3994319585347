export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export function plusify(text) {
  return text.toString().toLowerCase().split(' ').join('+');
}

/**
 * Removes duplicated from an array
 * NOTE: Uses Set which requires polyfills
 */
export function dedupe(array) {
  return [...new Set(array)];
}

/**
 * Takes slug style string and removes the '-' and
 * replaces with a space
 */
export function reverseSlugify(string) {
  return string.replace(/-/g, ' ');
}

export function reversePlusify(string) {
  return string.split('+').join(' ');
}

export function truncate(width) {
  return `
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

export function lineClamp(lines) {
  return `
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `;
}

export function formatTime(a, b = 'no') {
  const time = a;
  const hour = Math.floor(time / 3600);
  const exactHour = hour * 3600;
  const minute = time - exactHour;
  const exactMinute = Math.ceil(minute / 60);
  let meridiemFormat, meridiemHour, minuteFormat, hourFormat, timeFormated;

  if (hour >= 12) {
    meridiemFormat = 'pm';
  } else {
    meridiemFormat = 'am';
  }

  if (exactMinute < 10) {
    minuteFormat = `0${exactMinute}`;
  } else {
    minuteFormat = exactMinute;
  }

  if (hour < 10) {
    hourFormat = `0${hour}`;
  } else {
    hourFormat = hour;
  }

  if (b === 'yes') {
    if (hour >= 13) {
      meridiemHour = hour - 12;
    } else {
      meridiemHour = hour;
    }
    timeFormated = `${meridiemHour}.${minuteFormat}${meridiemFormat}`;
  } else {
    timeFormated = `${hourFormat}.${minuteFormat}`;
  }

  return timeFormated;
}

export function formatMarkerTitle(a) {
  const str = a;
  let res = str.replace('_', ' ');
  return res.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
    return letter.toUpperCase();
  });
}

export function limitText(text, max_length) {
  return text.length > max_length
    ? `${text.substring(0, max_length)}...`
    : text;
}

export function formatCardDate(time) {
  if (time) {
    let d;
    if (JSON.stringify(new Date(time)) !== 'null') {
      d = new Date(time);
    } else {
      d = new Date(time.replace(/-/g, '/').replace(/T/g, ' '));
    }

    const displayDate = d.getDate();
    const displayDay = d.getDay();
    const displayMonth = d.getMonth();
    const displayYear = d.getFullYear();
    const dayNames = [
      'SUN',
      'MON',
      'TUES',
      'WED',
      'THURS',
      'FRI',
      'SAT',
      'SUN',
    ];
    const dayName = dayNames[displayDay];
    const monthNames = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];
    const monthName = monthNames[displayMonth];
    const dateDisplay = (
      date = displayDate,
      day = dayName,
      month = monthName,
      year = displayYear
    ) => {
      return `${day} ${date} ${month} ${displayYear}`;
    };
    const x = dateDisplay();
    return x;
  }
}

export const timePeriod = (a, b, d = 'no') => {
  const padLeft = (c) => {
    if (c > 9) {
      return c;
    } else {
      return `${0}${c}`;
    }
  };

  let startDate;
  if (JSON.stringify(new Date(a)) !== 'null') {
    startDate = new Date(a);
  } else {
    startDate = new Date(a.replace(/-/g, '/').replace(/T/g, ' '));
  }

  const startTimeValue = `${padLeft(startDate.getHours())}:${padLeft(
    startDate.getMinutes().toString()
  )}`;
  const startTimeValueMeridem =
    startDate.getHours() * 3600 + startDate.getMinutes() * 60;
  let result;
  if (b) {
    let endDate;
    if (JSON.stringify(new Date(b)) !== 'null') {
      endDate = new Date(b);
    } else {
      endDate = new Date(b.replace(/-/g, '/').replace(/T/g, ' '));
    }
    const endTimeValue = `${padLeft(endDate.getHours())}:${padLeft(
      endDate.getMinutes().toString()
    )}`;
    const endTimeValueMeridem =
      endDate.getHours() * 3600 + endDate.getMinutes() * 60;
    if (d === 'yes') {
      result = `${formatTime(startTimeValueMeridem, 'yes')} - ${formatTime(
        endTimeValueMeridem,
        'yes'
      )}`;
    } else {
      result = `${startTimeValue} - ${endTimeValue}`;
    }
  } else {
    if (d === 'yes') {
      result = `${formatTime(startTimeValueMeridem, 'yes')}`;
    } else {
      result = `${startTimeValue}`;
    }
  }

  return result;
};

export const openingTimes = (a) => {
  const d = new Date();
  const displayDay = d.getDay();
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const objToArray = Object.keys(a).reduce((acc, curr) => {
    return [
      ...acc,
      {
        id: curr,
        fieldDay: a[curr].entity.fieldDay,
        fieldOpeningTime: a[curr].entity.fieldOpeningTime,
        fieldClosingTime: a[curr].entity.fieldClosingTime,
        fieldBoolean: a[curr].entity.fieldBoolean,
      },
    ];
  }, []);

  const compareDays = (c, d, e) => {
    if (c === d) {
      if (e === 'Closed') {
        return 'closed';
      } else {
        return 'open';
      }
    } else {
      return '';
    }
  };

  const todayOpeningTime = () => {
    let todayTime;
    const today = objToArray.filter((time) => {
      return time.fieldDay === dayNames[displayDay];
    });

    if (today.length) {
      if (today[0].fieldBoolean) {
        todayTime = 'Closed';
      } else {
        todayTime = `${formatTime(today[0].fieldOpeningTime)} - ${formatTime(
          today[0].fieldClosingTime
        )}`;
      }
    } else {
      if (today.fieldBoolean) {
        todayTime = 'Closed';
      } else {
        todayTime = `${formatTime(today.fieldOpeningTime)} - ${formatTime(
          today.fieldClosingTime
        )}`;
      }
    }

    return todayTime;
  };

  const weekOpeningTime = () => {
    const today = objToArray.map((time) => {
      let todayTime;
      if (time.fieldBoolean) {
        todayTime = `<li class="${compareDays(
          time.fieldDay,
          dayNames[displayDay],
          'Closed'
        )}"><span>${time.fieldDay}: </span><span>Closed</span></li>`;
      } else {
        todayTime = `<li class="${compareDays(
          time.fieldDay,
          dayNames[displayDay],
          'Opened'
        )}"><span>${time.fieldDay}:</span> <span>${formatTime(
          time.fieldOpeningTime
        )} - ${formatTime(time.fieldClosingTime)}</span></li>`;
      }
      return todayTime;
    });
    return today.join('');
  };

  return { todayOpening: todayOpeningTime(), weekOpenings: weekOpeningTime() };
};

export const campusAddress = (campus) => {
  let name;
  let address;
  switch (campus) {
    case 'egerton':
      name = 'Egerton Road';
      address = 'Guildford, Surrey, GU2 7XU';
      break;
    case 'hazel':
      name = 'Hazel Farm';
      address = 'Guildford, Surrey, GU2 9PL';
      break;
    case 'manor':
      name = 'Manor Park Campus';
      address = 'Alexander Fleming Road, Guildford, Surrey, GU2 7YW';
      break;
    case 'stag':
      name = 'Stag Hill campus';
      address = 'Guildford, Surrey, GU2 7XH';
      break;
    case 'research':
      name = 'Surrey Research Park';
      address =
        'Surrey Technology Centre, 40 Occam Road Guildford, Surrey GU2 7YG';
      break;
    case 'ssp':
      name = 'Surrey Sports Park';
      address = 'Richard Meyjes Road, Guildford, Surrey, GU2 7AD';
      break;
    case 'vet':
      name = 'School of Veterinary Medicine';
      address = 'Daphne Jackson Road, Guildford, Surrey, GU2 7XH';
      break;
    default:
      name = '';
      address = '';
  }
  return `${name}, ${address}`;
};
