import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import media from '../../../../utilities/breakpoints.js';

const StyledHeading = styled.h1`
  font-size: 26px;
  ${(props) =>
    props.context === 'hubpage' &&
    css`
      font-size: 32px;
    `}
  ${media.md`
    ${(props) =>
      props.context === 'hubpage' &&
      css`
        font-size: 40px;
      `}
  `}
  ${media.lg`
    font-size: 36px;
    ${(props) =>
      props.context === 'hubpage' &&
      css`
        font-size: 46px;
      `}
  `}
`;

const Title = ({ as: Element, className, children, context }) => {
  return (
    <StyledHeading className={className} as={Element} context={context}>
      {children}
    </StyledHeading>
  );
};

Title.defaultProps = {
  as: 'h1',
};

Title.propTypes = {
  className: PropTypes.string,
  as: PropTypes.node,
  children: PropTypes.string,
  context: PropTypes.string,
};

export default Title;
