import gql from 'graphql-tag';

export const Copy = gql`
  fragment ParagraphCopy on ParagraphCopy {
    ... on ParagraphCopy {
      uuid: uuid
      paragraphType: type {
        targetId
      }
      title: fieldTitle
      text: fieldText {
        value
        format
        processed
      }
      anchor: fieldUrl
    }
  }
`;

export const Image = gql`
  fragment ParagraphImage on ParagraphImage {
    ... on ParagraphImage {
      uuid
      paragraphType: type {
        targetId
      }
      field: fieldImage {
        entity {
          ... on MediaImage {
            image: fieldMediaImage {
              alt
              generic: derivative(style: GENERICIMAGE) {
                url
                height
                width
              }
              generic_placeholder: derivative(style: GENERICIMAGEPLACEHOLDER) {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const Video = gql`
  fragment ParagraphVideo on ParagraphVideo {
    ... on ParagraphVideo {
      uuid
      paragraphType: type {
        targetId
      }
      field: fieldVideo {
        video: entity {
          ... on MediaRemoteVideo {
            url: fieldMediaOembedVideo
            thumbnail {
              title
              url
            }
            title: entityLabel
          }
        }
      }
    }
  }
`;

export const File = gql`
  fragment ParagraphFile on ParagraphFile {
    ... on ParagraphFile {
      uuid
      paragraphType: type {
        targetId
      }
      title: fieldTitle
      fieldFile {
        entity {
          ... on MediaFile {
            fieldMediaFile {
              entity {
                filesize
                filemime
                filename
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const Highlight = gql`
  fragment ParagraphHighlight on ParagraphHighlight {
    ... on ParagraphHighlight {
      title: fieldTitle
      subheading: fieldSubheading
      uuid: uuid
      paragraphType: type {
        targetId
      }
      text: fieldText {
        value
      }
      image: fieldImage {
        entity {
          ... on MediaImage {
            fieldMediaImage {
              alt
              highlight: derivative(style: HIGHLIGHT) {
                url
                width
                height
              }
              highlight_placeholder: derivative(style: HIGHLIGHTPLACEHOLDER) {
                url
              }
            }
          }
        }
      }
      cta: fieldLink {
        title
        url {
          path
          routed
        }
      }
    }
  }
`;

export const Grid = gql`
  fragment ParagraphGrid on ParagraphGrid {
    ... on ParagraphGrid {
      uuid: entityUuid
      title: fieldTitle
      paragraphType: type {
        targetId
      }
      items: fieldComponents {
        entity {
          ... on ParagraphGridItem {
            uuid: uuid
            title: fieldTitle
            info: fieldCaption
            link: fieldLink {
              url {
                path
                routed
              }
            }
            image: fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    alt
                    gridItem: derivative(style: GRIDITEM) {
                      url
                      width
                      height
                    }
                    gridItemPlaceholder: derivative(
                      style: GRIDITEMPLACEHOLDER
                    ) {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Links = gql`
  fragment ParagraphLinks on ParagraphLinks {
    ... on ParagraphLinks {
      uuid: entityUuid
      paragraphType: type {
        targetId
      }
      title: fieldTitle
      items: fieldThreeComponents {
        entity {
          ... on ParagraphLink {
            uuid: uuid
            link: fieldLink {
              title
              url {
                path
                routed
              }
            }
            newWindow: fieldBoolean
          }
        }
      }
    }
  }
`;

export const AccordionGroup = gql`
  fragment ParagraphAccordionGroup on ParagraphAccordionGroup {
    uuid
    paragraphType: type {
      targetId
    }
    title: fieldTitle
    intro: fieldDescription {
      value
    }
    anchor: fieldTextShort
    accordions: fieldComponents {
      accordion: entity {
        ... on ParagraphAccordion {
          uuid
          paragraphType: type {
            targetId
          }
          title: fieldTitle
          paragraphs: fieldComponents {
            paragraph: entity {
              ... on ParagraphCopy {
                uuid
                paragraphType: type {
                  targetId
                }
                text: fieldText {
                  value
                  format
                  processed
                }
              }
              ... on ParagraphFile {
                uuid
                paragraphType: type {
                  targetId
                }
                title: fieldTitle
                fieldFile {
                  entity {
                    ... on MediaFile {
                      fieldMediaFile {
                        entity {
                          filesize
                          filemime
                          filename
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Hero = gql`
  fragment HeroImage on MediaImage {
    fieldMediaImage {
      alt
      hero_large: derivative(style: HEROLARGE) {
        url
        width
        height
      }
      hero_large_placeholder: derivative(style: HEROLARGEPLACEHOLDER) {
        url
      }
    }
  }
`;

export const InlineTextandImage = gql`
  fragment ParagraphInlineTextAndImage on ParagraphInlineTextAndImage {
    ... on ParagraphInlineTextAndImage {
      ... on ParagraphInlineTextAndImage {
        uuid
        paragraphType: type {
          targetId
        }
        text: fieldText {
          value
        }
        fieldTitle
        image: fieldImage {
          entity {
            ... on MediaImage {
              fieldMediaImage {
                alt
                title
                large: derivative(style: LARGE) {
                  url
                  height
                  width
                }
                large_placeholder: derivative(style: LARGE) {
                  url
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const InlineTextandVideo = gql`
  fragment ParagraphInlineTextAndVideo on ParagraphInlineTextAndVideo {
    ... on ParagraphInlineTextAndVideo {
      uuid
      paragraphType: type {
        targetId
      }
      field: fieldVideo {
        video: entity {
          ... on MediaRemoteVideo {
            url: fieldMediaOembedVideo
            thumbnail {
              title
              url
            }
            title: entityLabel
          }
        }
      }
      text: fieldText {
        value
      }
    }
  }
`;

export const Contact = gql`
  fragment ParagraphContact on ParagraphContact {
    ... on ParagraphContact {
      uuid
      paragraphType: type {
        targetId
      }
      department: fieldTitle
      team: fieldSubheading
      room: fieldRoom
      telephoneTitle: fieldPhoneTitle
      telephoneNumber: fieldPhoneNumber
      telephoneDescription: fieldPhoneNumberDesc
      email: fieldEmailAddress
      emailTitle: fieldEmailTitle
      oneSurreyTitle: fieldOnesurreyTitle
      telephoneAlternative: fieldPhoneNumberAlt
      telephoneAlternativeDescription: fieldPhoneNumberAltDesc
      telephoneTitleAlternative: fieldPhoneTitleAlt
      oneSurreyLink: fieldLink {
        title
        url {
          path
        }
      }
      appointmentTitle: fieldTableContentHeading
      appointmentLink: fieldCallToActionUrl {
        title
        url {
          path
        }
      }
      image: fieldImage {
        entity {
          ... on MediaImage {
            fieldMediaImage {
              derivative(style: HEROLARGE) {
                url
              }
              alt
            }
          }
        }
      }
      openingTimes: fieldOpeningTimes {
        entity {
          ... on ParagraphOpeningTimes {
            uuid
            paragraphType: type {
              targetId
            }
            fieldDay
            fieldOpeningTime
            fieldClosingTime
            fieldBoolean
          }
        }
      }
      location: fieldContentReference {
        entity {
          ... on NodeLocation {
            latitude: fieldLatitude
            longitude: fieldLongitude
            campus: fieldCampus
            title
          }
        }
      }
    }
  }
`;

export const TwoColTable = gql`
  fragment ParagraphTwoColumnTable on ParagraphTwoColumnTable {
    ... on ParagraphTwoColumnTable {
      uuid
      paragraphType: type {
        targetId
      }
      title: fieldTableHeading
      icon: fieldTableHeaderIcon {
        title
        alt
        entity {
          url
        }
      }
      tableContentHeader: fieldTableContentHeading
      firstColumnHeader: fieldFirstColumnHeader
      firstColumnBody: fieldFirstColumnBody {
        processed
      }
      firstColumnSmallprint: fieldFirstColumnSmallprint
      secondColumnHeader: fieldSecondColumnHeader
      secondColumnBody: fieldSecondColumnBody {
        processed
      }
      secondColumnSmallprint: fieldSecondColumnSmallprint
      accordionText: fieldTableAccordionText
      accordionBody: fieldTableAccordionBody {
        processed
      }
      ctaText: fieldCallToActionText
      ctaUrl: fieldCallToActionUrl {
        uri
        title
        url {
          routed
          path
          ... on EntityCanonicalUrl {
            path
          }
        }
      }
    }
  }
`;

export const SocialMedia = gql`
  fragment ParagraphSocialMedia on ParagraphSocialMedia {
    ... on ParagraphSocialMedia {
      uuid
      paragraphType: type {
        targetId
      }
      title: fieldTitle
      fieldTwitterAccount {
        entity {
          ... on NodeTwitterAccount {
            twitterAddress: title
          }
        }
      }
      fieldInstagramAccount {
        entity {
          ... on NodeInstagramAccount {
            instagramAddress: title
          }
        }
      }
      fieldComponent {
        entity {
          ... on ParagraphSocialMediaIcons {
            uuid
            paragraphType: type {
              targetId
            }
            fieldComponents {
              entity {
                ... on ParagraphSocialMediaIcon {
                  uuid
                  paragraphType: type {
                    targetId
                  }
                  socialNetwork: fieldSocialNetwork
                  socialNetworkUrl: fieldUrl
                  socialUuid: uuid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SocialMediaTag = gql`
  fragment ParagraphSocialTag on ParagraphSocialTag {
    ... on ParagraphSocialTag {
      uuid
      paragraphType: type {
        targetId
      }
      title: fieldTitle
      hashLink: fieldLink {
        url {
          path
        }
        hashTag: title
      }
      fieldTextShort
      fieldComponent {
        entity {
          ... on ParagraphSocialMediaIcons {
            uuid
            paragraphType: type {
              targetId
            }
            fieldComponents {
              entity {
                ... on ParagraphSocialMediaIcon {
                  uuid
                  paragraphType: type {
                    targetId
                  }
                  socialNetworkUrl: fieldUrl
                  socialNetwork: fieldSocialNetwork
                  socialUuid: uuid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const TwitterPost = gql`
  fragment ParagraphTwitterPost on ParagraphTwitterPost {
    ... on ParagraphTwitterPost {
      url: fieldUrl
      uuid
      paragraphType: type {
        targetId
      }
    }
  }
`;

export const FacebookVideo = gql`
  fragment ParagraphFacebookVideo on ParagraphFacebookVideo {
    ... on ParagraphFacebookVideo {
      uuid
      paragraphType: type {
        targetId
      }
      url: fieldUrl
    }
  }
`;

export const FacebookPost = gql`
  fragment ParagraphFacebookPost on ParagraphFacebookPost {
    ... on ParagraphFacebookPost {
      url: fieldUrl
      uuid
      paragraphType: type {
        targetId
      }
    }
  }
`;

export const InstagramPost = gql`
  fragment ParagraphInstagramPost on ParagraphInstagramPost {
    ... on ParagraphInstagramPost {
      uuid
      paragraphType: type {
        targetId
      }
      url: fieldUrl
    }
  }
`;

export const ImageGallery = gql`
  fragment ParagraphImageGallery on ParagraphImageGallery {
    ... on ParagraphImageGallery {
      uuid
      paragraphType: type {
        targetId
      }
      fieldTitle
      fieldComponents {
        entity {
          ... on ParagraphImageGallerySlide {
            uuid
            paragraphType: type {
              targetId
            }
            fieldCaption
            fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    alt
                    small: derivative(style: IMAGEGALLERYSMALL) {
                      url
                    }
                    medium: derivative(style: IMAGEGALLERYMEDIUM) {
                      url
                    }
                    large: derivative(style: IMAGEGALLERYLARGE) {
                      url
                    }
                    thumb: derivative(style: IMAGEGALLERYTHUMBNAIL) {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const VideoGallery = gql`
  fragment ParagraphVideoGallery on ParagraphVideoGallery {
    ... on ParagraphVideoGallery {
      uuid: uuid
      paragraphType: type {
        targetId
      }
      title: fieldTitle
      anchor: fieldTextShort
      intro: fieldDescription {
        value
      }
      videos: fieldComponents {
        entity {
          ... on ParagraphVideoBlock {
            uuid: uuid
            paragraphType: type {
              targetId
            }
            fieldCaption
            fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    alt
                    video: derivative(style: IMAGEGALLERYMEDIUM) {
                      url
                    }
                    thumbnail: derivative(style: IMAGEGALLERYTHUMBNAIL) {
                      url
                    }
                  }
                }
              }
            }
            fieldVideo {
              entity {
                ... on MediaRemoteVideo {
                  remoteUrl: fieldMediaOembedVideo
                  thumbnail {
                    url
                  }
                  title: entityLabel
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const NewsStory = gql`
  fragment ParagraphNewsStory on ParagraphNewsStory {
    ... on ParagraphNewsStory {
      fieldContentReference {
        story: entity {
          uuid: entityUuid
          title
          url: entityUrl {
            path
          }
          ... on NodeNews {
            fieldHub {
              entity {
                entityLabel
              }
            }
            image: fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    alt
                    derivative(style: MYSURREYOTHERNEWSTEASER) {
                      url
                    }
                  }
                }
              }
            }
            created: entityCreated
          }
        }
      }
    }
  }
`;

export const InlineIconButton = gql`
  fragment ParagraphButton on ParagraphButton {
    ... on ParagraphButton {
      buttonLink: fieldLink {
        title
        url {
          path
        }
      }
      icon: fieldTableHeaderIcon {
        title
        alt
        entity {
          url
        }
      }
    }
  }
`;

// export const CampaignCTA = gql`
//   fragment ParagraphLink on ParagraphLink {
//     ... on ParagraphLink {
//       fieldLink {
//         title
//         uri
//         url {
//           path
//         }
//       }
//     }
//   }
// `;

export const ParagraphTextBlock = gql`
  fragment ParagraphTextBlock on ParagraphTextBlock {
    ... on ParagraphTextBlock {
      uuid: entityUuid
      paragraphType: type {
        targetId
      }
      textBlockLink: fieldComponent {
        entity {
          ... on ParagraphLink {
            fieldLink {
              title
            }
            fieldBoolean
          }
        }
      }
      anchor: fieldTextShort
      textBlockTitle: fieldTitle
      textBlockContent: fieldText {
        value
      }
      textBlockAlignment: fieldTextAlignment
      textBlockMedia: fieldOneParagraph {
        entity {
          ... on ParagraphSloganBlock {
            uuid: uuid
            paragraphType: type {
              targetId
            }
            fieldTitle
          }
          ... on ParagraphTextBlockWithQuote {
            uuid: uuid
            paragraphType: type {
              targetId
            }
            fieldTitle
            fieldSubheading
            fieldLink {
              url {
                path
              }
            }
            fieldTextShort
            fieldCaption
            fieldHighlightImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    url
                    alt
                    derivative(style: SQUARE200) {
                      url
                      height
                      width
                    }
                  }
                }
              }
            }
          }
          ... on ParagraphImageBlock {
            uuid: uuid
            paragraphType: type {
              targetId
            }
            fieldCaption
            fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    alt
                    derivative(style: TEXTBLOCKIMAGE) {
                      url
                      height
                      width
                    }
                    placeholder: derivative(style: TEXTBLOCKIMAGE) {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on ParagraphVideoBlock {
            uuid: uuid
            paragraphType: type {
              targetId
            }
            fieldCaption
            fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    derivative(style: TEXTBLOCKIMAGE) {
                      url
                    }
                    placeholder: derivative(style: TEXTBLOCKIMAGE) {
                      url
                    }
                  }
                }
              }
            }
            fieldVideo {
              entity {
                ... on MediaRemoteVideo {
                  remoteUrl: fieldMediaOembedVideo
                  thumbnail {
                    title
                    url
                  }
                  title: entityLabel
                }
              }
            }
          }
        }
      }
      fieldComponent {
        entity {
          ... on ParagraphLink {
            fieldLink {
              title
              url {
                path
              }
            }
          }
        }
      }
    }
  }
`;

export const ParagraphCtaBanner = gql`
  fragment ParagraphCtaBanner on ParagraphCtaBanner {
    ... on ParagraphCtaBanner {
      uuid: entityUuid
      paragraphType: type {
        targetId
      }
      fieldTitle
      fieldDescription {
        processed
      }
      fieldComponent {
        entity {
          ... on ParagraphLink {
            openNewWindow: fieldBoolean
            fieldLink {
              title
              url {
                path
              }
            }
          }
        }
      }
    }
  }
`;

export const ParagraphCardCarousel = gql`
  fragment ParagraphCardCarousel on ParagraphCardCarousel {
    ... on ParagraphCardCarousel {
      uuid: entityUuid
      paragraphType: type {
        targetId
      }
      title: fieldTitle
      anchor: fieldTextShort
      intro: fieldDescription {
        value
      }

      cardGroups: fieldComponents {
        entity {
          ... on ParagraphCardAndTitle {
            groupTitle: fieldTitle
            uuid: uuid
            fieldComponents {
              entity {
                ... on ParagraphAutomaticCard {
                  uuid: entityUuid
                  paragraphType: type {
                    targetId
                  }
                  openNewWindow: fieldBoolean
                  fieldImage {
                    entity {
                      ... on MediaImage {
                        fieldMediaImage {
                          generic: derivative(style: CAMPAIGNCARD) {
                            url
                            width
                            height
                          }
                          placeholder: derivative(
                            style: GENERICIMAGEPLACEHOLDER
                          ) {
                            url
                            width
                            height
                          }
                        }
                      }
                    }
                  }
                  fieldContentReference {
                    entity {
                      ... on NodeNews {
                        fieldIntroText {
                          value
                        }
                        entityLabel
                        entityUrl {
                          path
                        }
                        fieldImage {
                          entity {
                            ... on MediaImage {
                              fieldMediaImage {
                                generic: derivative(style: CAMPAIGNCARD) {
                                  url
                                  width
                                  height
                                }
                                placeholder: derivative(
                                  style: GENERICIMAGEPLACEHOLDER
                                ) {
                                  url
                                  width
                                  height
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on ParagraphManualCard {
                  uuid: entityUuid
                  paragraphType: type {
                    targetId
                  }
                  fieldImage {
                    entity {
                      ... on MediaImage {
                        fieldMediaImage {
                          generic: derivative(style: CAMPAIGNCARD) {
                            url
                            width
                            height
                          }
                          placeholder: derivative(
                            style: GENERICIMAGEPLACEHOLDER
                          ) {
                            url
                            width
                            height
                          }
                        }
                      }
                    }
                  }
                  cardTitle: fieldTitle
                  cardDescription: fieldDescription {
                    value
                  }
                  openNewWindow: fieldBoolean
                  cardLink: fieldLink {
                    url {
                      path
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ParagraphLogoStrip = gql`
  fragment ParagraphLogoStrip on ParagraphLogoStrip {
    ... on ParagraphLogoStrip {
      paragraphType: type {
        targetId
      }
      uuid: uuid
      fieldComponents {
        entity {
          ... on ParagraphLogo {
            uuid: uuid
            openNewWindow: fieldBoolean
            fieldLink {
              url {
                path
              }
            }
            fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    logo: derivative(style: LOGOSTRIPLOGO) {
                      url
                      width
                      height
                    }
                    alt
                  }
                }
              }
            }
          }
        }
      }
      title: fieldTitle
      anchor: fieldTextShort
      intro: fieldDescription {
        value
      }
    }
  }
`;

export const ParagraphFullWidthBlock = gql`
  fragment ParagraphFullWidthBlock on ParagraphFullWidthBlock {
    ... on ParagraphFullWidthBlock {
      paragraphType: type {
        targetId
      }
      uuid: uuid
      fieldTitle
      anchor: fieldUrl
      fieldUnlimitedComponents {
        entity {
          ... on ParagraphTextQuote {
            paragraphType: type {
              targetId
            }
            uuid: uuid
            fieldText {
              value
            }
            fieldLink {
              url {
                path
              }
            }
            fieldTextShort
            fieldSubheading
            fieldTitle
          }
          ... on ParagraphFullWidthBlockImage {
            paragraphType: type {
              targetId
            }
            uuid: uuid
            fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    alt
                    generic: derivative(style: QUOTEBLOCKIMAGE) {
                      url
                      height
                      width
                    }
                    placeholder: derivative(style: GENERICIMAGEPLACEHOLDER) {
                      url
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ParagraphEventBlock = gql`
  fragment ParagraphEventBlock on ParagraphEventBlock {
    ... on ParagraphEventBlock {
      paragraphType: type {
        targetId
      }
      uuid: uuid
      title: fieldTitle
      anchor: fieldTextShort
      card: fieldFeaturedEvent {
        entity {
          ... on NodeEvent {
            title
            intro: fieldIntroText {
              value
            }
            eventStart: fieldStartDate {
              value
              date
            }
            eventEnd: fieldEndDate {
              value
              date
            }
            allDayEvent: fieldBooleanOne
            eventLink: entityUrl {
              path
            }
            image: fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    alt
                    generic: derivative(style: CAMPAIGNCARD) {
                      url
                      width
                      height
                    }
                    placeholder: derivative(style: GENERICIMAGEPLACEHOLDER) {
                      url
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
      newWindow: fieldBoolean
    }
  }
`;

export const ParagraphIssuEmbed = gql`
  fragment ParagraphIssuEmbed on ParagraphIssuEmbed {
    ... on ParagraphIssuEmbed {
      uuid
      paragraphType: type {
        targetId
      }
      fieldUrl
      fieldText {
        value
      }
      fieldTitle
      fieldSurreyBranded
    }
  }
`;

export const ParagraphEvents = gql`
  fragment ParagraphEvents on ParagraphEvents {
    ... on ParagraphEvents {
      uuid
      paragraphType: type {
        targetId
      }
      title: fieldTableHeading
      events: fieldTwoComponents {
        paragraph: entity {
          ... on ParagraphEvent {
            uuid
            paragraphType: type {
              targetId
            }
            event: fieldEvent {
              entity {
                url: entityUrl {
                  path
                }
                ... on NodeEvent {
                  id: entityUuid
                  image: fieldImage {
                    entity {
                      ... on MediaImage {
                        fieldMediaImage {
                          alt
                          derivative(style: MYSURREYOTHERNEWSTEASER) {
                            url
                            width
                            height
                          }
                        }
                      }
                    }
                  }
                  title
                  start: fieldStartDate {
                    value
                  }
                  end: fieldEndDate {
                    value
                  }
                  hubName: fieldHub {
                    hubTitle: entity {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
