import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloClient } from 'apollo-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';
// import { createPersistedQueryLink } from 'apollo-link-persisted-queries';

import introspectionQueryResultData from './utilities/apollo/fragmentTypes.json';
import TagManager from 'react-gtm-module';

import 'sanitize.css';

import App from './App.js';

const tagManagerArgs = {
  gtmId: 'GTM-KWJKD2M',
  dataLayerName: 'PageDataLayer',
};

TagManager.initialize(tagManagerArgs);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });

const container = document.getElementById('root');

const root = createRoot(container);

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        /* eslint-disable no-console */
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }),
  new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),
]);

const client = new ApolloClient({
  link,
  cache,
});

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
