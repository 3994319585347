import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from '../Campaign/Header/Header.js';
import Footer from '../Campaign/Footer/Footer.js';
import Title from '../Campaign/Common-components/Title/Title.js';

import { StyledCampaignLinkContainer } from '../../CampaignGlobalStyles.js';

const StyledBody = styled.section`
  height: calc(100vh - 264px);
`;

const StyledParagraph = styled.p`
  a {
    color: rgba(57, 3, 59, 1);
  }
`;

const CampaignNotFound = () => {
  return (
    <div>
      <Header context="CampaignTheme" />
      <StyledCampaignLinkContainer>
        <StyledBody className="container">
          <Title>No Campaign found!</Title>

          <p>Sorry, no campaign found here.</p>

          <StyledParagraph>
            If you think this is an error please{' '}
            <a href="mailto:web-support@surrey.ac.uk">contact us</a>, you can
            return to the{' '}
            <a href="/" title="Return to MySurrey Homepage">
              MySurrey homepage
            </a>
          </StyledParagraph>
        </StyledBody>
      </StyledCampaignLinkContainer>

      <Footer />
    </div>
  );
};

CampaignNotFound.propTypes = {
  context: PropTypes.string.isRequired,
};

export default CampaignNotFound;
